import { FunctionComponent, useContext } from 'react'
import { IconName, SidebarTab } from '@components/base'
import { AppRoute, buildRoute, RouterContext } from '@lib/app/routing'
import { AppSettingsContext } from '@lib/app/settings'
import classes from '@components/base/Sidebar/Sidebar.module.scss'

interface IProfileSidebarNavigationProps {}

export const ProfileSidebarNavigation: FunctionComponent<IProfileSidebarNavigationProps> = (_) => {
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const pathname = useContext(RouterContext)?.data.location.pathname

    return (
        <nav className={`${classes['sidebar']} ${isDark && classes['sidebar-dark']}`}>
            <SidebarTab url={buildRoute(AppRoute.Overview)} icon={IconName.Dashboard} text='Overview' />
            <SidebarTab
                url={buildRoute(AppRoute.AddStream)}
                icon={IconName.Location}
                text='Add Project'
                isActive={pathname.includes('add-stream')}
                svgProperties={['stroke']}
            />
            <SidebarTab
                url={buildRoute(AppRoute.Profile)}
                icon={IconName.Users}
                text='Profile'
                isActive={pathname.includes('profile')}
                svgProperties={['stroke']}
            />
            <SidebarTab
                url={buildRoute(AppRoute.Identity)}
                icon={IconName.Document}
                text='Identity'
                isActive={pathname.includes('identity')}
                svgProperties={['stroke']}
            />
        </nav>
    )
}
