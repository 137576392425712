export class BaseApiService {
    protected baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    private async handle(response: Response): Promise<Response | any> {
        if (response.ok) {
            if (response.type === 'cors') {
                return {}
            } else if (response.type === 'basic') {
                const contentType = response.headers.get('content-type')
                switch (contentType) {
                    case 'text/plain':
                        return response.text()
                    case 'application/json':
                        return response.json()
                    case 'application/octet-stream':
                        return response.blob()
                    default:
                        return response.body
                }
            } else {
                return response.json()
            }
        } else {
            const { status, statusText } = response
            const message = `[${status}]: ${statusText}`
            console.error(message)
        }
    }

    protected async delete(endpoint: string, params?: URLSearchParams): Promise<any> {
        const url = new URL(endpoint, this.baseUrl)
        if (params) {
            url.search = params.toString()
        }
        const response = await fetch(url.toString(), { method: 'DELETE' })
        return this.handle(response)
    }

    protected async get(endpoint: string, params?: URLSearchParams): Promise<any> {
        const url = new URL(endpoint, this.baseUrl)
        if (params) {
            url.search = params.toString()
        }
        const response = await fetch(url.toString(), { method: 'GET' })
        return this.handle(response)
    }

    protected async post(endpoint: string, body: unknown | FormData, contentType: string = 'application/json') {
        const url = new URL(endpoint, this.baseUrl)

        const headers = new Headers({})
        const isFormData = body instanceof FormData
        if (!isFormData) {
            headers.set('Content-Type', contentType)
        }

        const preparedBody = isFormData ? body : JSON.stringify(body)
        const response = await fetch(url.toString(), {
            method: 'POST',
            headers,
            body: preparedBody,
        })
        return this.handle(response)
    }
}
