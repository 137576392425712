import { FunctionComponent, RefObject, useEffect, useRef, useState } from 'react'
import { ITableRowProps, Table, TableRow, Link, LinkTarget, Text, OutsideAlerter, TableTitle } from '@components/base'
import { config } from '@constants'
import { getNestedReadingValue, ISensorData } from '@lib/app/sensor'
import { dateToReadable } from '@lib/utils'
import { PROJECT_SENSOR_ANNOTATION_TABLE_FIELDS, ProjectSensorAnnotationTableRow } from '@components/app'

export const ProjectSensorDataTableRow: FunctionComponent<ITableRowProps<ISensorData>> = (props) => {
    const { data, displayAbove = false } = props
    const { timestamp, value, unit, score, address, annotations } = data ?? ({} as ISensorData)
    const [showAnnotations, setShowAnnotations] = useState<boolean>(false)
    const annotationTableRef = useRef<HTMLDivElement>(null)

    let rowHeight = 0

    function onRef(ref: RefObject<HTMLTableRowElement>): void {
        rowHeight = ref?.current?.getBoundingClientRect()?.height ?? 0
    }

    useEffect(() => {
        if (displayAbove) {
            const tableHeight = annotationTableRef?.current?.getBoundingClientRect()?.height ?? 0
            /**
             * NOTE: We add 1px here to compensate for the border between rows.
             */
            annotationTableRef?.current?.style.setProperty('transform', `translateY(${-(rowHeight + tableHeight)}px)`)
        }
    }, [showAnnotations, displayAbove])

    const cells = [
        <div className='cursor-pointer' onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>{dateToReadable(timestamp)}</Text>
        </div>,
        <div className='cursor-pointer' onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>
                {getNestedReadingValue(value)[1]} {unit.replace('[', '').replace(']', '')}
            </Text>
        </div>,
        <div className='cursor-pointer' onClick={() => setShowAnnotations(!showAnnotations)}>
            <Text type='body1'>{score}</Text>
        </div>,
        <Link url={`${config.EXPLORER_URL}/search/${address}`} target={LinkTarget.Blank}>
            {address.slice(0, 5)}...{address.slice(-24)}
        </Link>,
    ]

    return showAnnotations ? (
        <>
            <TableRow onRef={onRef} hideBorders>
                {cells}
            </TableRow>
            <OutsideAlerter onClick={() => setShowAnnotations(false)}>
                <div
                    ref={annotationTableRef}
                    className='absolute shadow-md z-50 w-[70vw] bg-surface-1 dark:bg-surface-1-dark clipped-right animate-fade-in'
                >
                    <Table
                        fields={PROJECT_SENSOR_ANNOTATION_TABLE_FIELDS}
                        data={annotations}
                        RowTemplate={ProjectSensorAnnotationTableRow}
                        TitleTemplate={() => TableTitle({ title: 'Annotations' })}
                        unClipped
                        disableFooter
                    />
                </div>
            </OutsideAlerter>
        </>
    ) : (
        <TableRow>{cells}</TableRow>
    )
}
