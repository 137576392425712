import { useLocation, useParams } from 'react-router-dom'
import { cargoAverage, cargoCondidence, cargoCurrent, sheet, statusConnected, statusDisconnected } from '@assets'
import { ProjectDataSourceImage, ProjectLayout, ProjectSensorDataTable } from '@components/app'
import classes from './Flowmeter.module.scss'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { formatDate, strToSentenceCase } from '@lib/utils'
import { BarRangeChart, LineAreaChart, Pane, Skeleton, Tabs, Text } from '@components/base'
import {
    getSensorDataFromDashboardContext,
    ISensorData,
    ISensorContextData,
    sortSensorData,
    exportDataToFile,
    mapSensorDataToAnnotations,
} from '@lib/app/sensor'
import { ProjectDataSourceTitle } from '../ProjectDataSourceTitle'
import { AppSettingsContext } from '@lib/app/settings'
import { IChartData } from '@components/base/Charts/types.ts'
import { sortNestedReadings, transformReadings } from '@pages'
import { PublicAssetPath } from '@pages/Project/ProjectDataSources/ProjectDataSource/Sources/index.ts'

const nestedDataEmpty = {
    image: sheet,
    active: true,
    infoData: [
        {
            title: 'Sensor ID',
            value: '',
        },
        {
            title: 'Accuracy',
            value: '',
        },
        {
            title: 'Manufacturer',
            value: '',
        },
        {
            title: 'Installed on',
            value: '',
        },
        {
            title: 'Model',
            value: '',
        },
        {
            title: 'Last Calibration',
            value: '',
        },
        {
            title: 'Serial Number',
            value: '',
        },
        {
            title: 'Next Calibration',
            value: '',
        },
    ],
}

const flowmeterDataEmpty = [
    {
        img: cargoAverage,
        text: 'Latest Reading',
        value: '',
        valueUnit: '',
    },
    {
        img: cargoCurrent,
        text: 'Daily Readings',
        value: 0,
        valueUnit: '',
    },
    {
        img: cargoCondidence,
        text: 'Data confidence',
        value: 0,
        valueUnit: '%',
    },
]

export const NestedDataSource: FunctionComponent<{ sensorContext?: ISensorContextData }> = (props) => {
    const [activeTab, setActiveTab] = useState(0)

    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    const { sensorContext } = props
    //const imageUri = PublicAssetPath + slug + '/sensor.' + sensorContext?.equipment.name
    const imageUri = PublicAssetPath + 'testing/' + sensorContext?.equipment.id.split(' ').join('+')

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
    const oneYearAgo = new Date()
    oneYearAgo.setDate(oneYearAgo.getDate() - 365)

    const [nestedData, setNestedData] = useState<
        Array<{
            img: string
            text: string
            value: number | string
            valueUnit: string
        }>
    >(
        flowmeterDataEmpty.map((a) => {
            return { ...a }
        })
    )

    const [nestedInfo, setNestedInfo] = useState(
        nestedDataEmpty.infoData.map((a) => {
            return { ...a }
        })
    )

    const [sensorDataList, setSensorDataList] = useState<{ [key: string]: ISensorData[] }>({})
    const [graphData, setGraphData] = useState<Array<{ equipment: string; unit?: string; data: IChartData[] }>>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensorContext) {
            const sorted = transformReadings(sensorContext.readings)
            const newGraphData = sortNestedReadings(sorted, sensorContext.equipment.name)
            setGraphData(newGraphData)

            const readings: { [key: string]: ISensorData[] } = {}
            Object.entries(sorted).forEach((v) => {
                const [key, context] = v
                readings[key] = Object.values(context)
                    .map((v) => {
                        const readings = Array.from(Object.values(v)).map((v) => {
                            const unit = v.unit ? v.unit : sensorContext.equipment.units
                            return getSensorDataFromDashboardContext(v, unit)
                        })

                        readings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())

                        return readings
                    })
                    .flat()
            })

            setSensorDataList(readings)
            setLoading(false)
        }
    }, [sensorContext])

    useEffect(() => {
        if (sensorContext) {
            const numbers = [...nestedData]
            numbers[0].value = formatDate(sensorContext.lastUpdated)
            numbers[1].value = Object.entries(sensorContext.readings).filter((v) => {
                const [_, data] = v
                new Date().getTime() - new Date(data.timestamp).getTime() < 1000 * 60 * 60 * 24 /* 24 hours */
            }).length

            numbers[2].value = Math.round(sensorContext.avgcf * 10000) / 10000
            setNestedData(numbers)

            const newData = [...nestedInfo]
            newData[0].value = sensorContext.equipment.id
            newData[1].value = sensorContext.equipment.accuracy + ''
            newData[2].value = sensorContext.equipment.manufacturer
            newData[3].value = sensorContext.equipment.installed + ''
            newData[4].value = sensorContext.equipment.eqType
            // Last calibration
            newData[5].value = 'Never'
            newData[6].value = sensorContext.equipment.serialNo
            // Next calibration
            newData[7].value = 'Soon'
            setNestedInfo(newData)
        }
    }, [sensorContext])

    const TitleComponent = () => {
        return (
            <ProjectDataSourceTitle
                title={sensorContext?.equipment.name ?? 'Category'}
                url={url}
                canShowMenu={activeTab === 1}
                onExportClick={async (fileType) => {
                    const sortedSensorData = sortSensorData(
                        Object.entries(sensorContext?.readings ?? []).map(([key, value]) => ({
                            id: key,
                            ...getSensorDataFromDashboardContext(value, sensorContext?.equipment.units ?? ''),
                        }))
                    )
                    await exportDataToFile(fileType, [sortedSensorData, mapSensorDataToAnnotations(sortedSensorData)], {
                        filename: sensorContext?.id ?? 'Data',
                        sheetNames: ['Readings', 'Annotations'],
                        useHeaderRow: true,
                    })
                }}
            />
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']} onTabClick={setActiveTab}>
                <article className={classes.flowmeterWrapper}>
                    <div className={`${classes.flowmeterInfo} ${isDark && classes.flowmeterInfoDark}`}>
                        <figure className={classes.image}>
                            <ProjectDataSourceImage
                                uri={imageUri}
                                alt='Nested Image'
                                defaultImage={nestedDataEmpty.image}
                            />
                        </figure>
                        <div className={`${classes.rightSide} ${isDark && classes.rightSideDark}`}>
                            <img
                                src={nestedDataEmpty.active ? statusConnected : statusDisconnected}
                                className={classes.status}
                                alt='Status'
                            />
                            <div className={classes.data}>
                                {nestedInfo.map((data, index) => (
                                    <div className={classes.item} key={index}>
                                        <h6>{data.title}</h6>
                                        <p>{data.value || <Skeleton />}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-row space-x-4'>
                            {nestedData.map((data, index) => (
                                <div
                                    className='w-1/3 bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-col items-center justify-around p-6 space-y-4'
                                    key={index}
                                >
                                    <img src={data.img} alt='Image' />
                                    <Text type='body1' color='text-secondary'>
                                        {data.text}
                                    </Text>
                                    {typeof data.value === 'number' ? (
                                        <Text type='header3'>
                                            {data.value}{' '}
                                            <span className='font-24-28-400 text-[var(--text-secondary)]'>
                                                {data.valueUnit}
                                            </span>
                                        </Text>
                                    ) : (
                                        <Text type='header4'>{data.value} </Text>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <Pane>
                        {graphData.length === 1 ? (
                            <div className='p-2'>
                                <BarRangeChart
                                    title={{ title: graphData[0].equipment }}
                                    data={graphData[0].data}
                                    loading={!nestedInfo}
                                />
                            </div>
                        ) : (
                            Object.entries(graphData).map((v) => {
                                const [_, nested] = v
                                return (
                                    <div className='p-2'>
                                        {nested.data.length > 100 ? (
                                            <BarRangeChart
                                                data={nested.data}
                                                title={{ title: strToSentenceCase(nested.equipment) }}
                                                loading={!nestedInfo}
                                            />
                                        ) : (
                                            <LineAreaChart
                                                title={{ title: strToSentenceCase(nested.equipment) }}
                                                data={nested.data}
                                                loading={!nestedInfo}
                                                unit={nested.unit}
                                                start={oneYearAgo}
                                                end={new Date()}
                                            />
                                        )}
                                    </div>
                                )
                            })
                        )}
                    </Pane>
                </article>
                {Object.entries(sensorDataList).map((v) => {
                    const [key, value] = v
                    return (
                        <ProjectSensorDataTable
                            title={strToSentenceCase(key)}
                            loading={loading}
                            sensorDataList={value}
                        />
                    )
                })}
            </Tabs>
        </ProjectLayout>
    )
}
