import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import { INotification, NotificationContext, NotificationSystem } from '@lib/core/notification'
import { generateRandomId } from '@lib/utils'
import { ToastContainer } from './ToastContainer.tsx'

interface INotificationProvider {
    children: ReactNode
}

export const NotificationProvider: FunctionComponent<INotificationProvider> = (props) => {
    const { children } = props

    const [notifications, setNotifications] = useState<INotification[]>([])

    const addNotification = useCallback((notification: Omit<INotification, 'id'>) => {
        const id = generateRandomId()
        setNotifications((prev) => [...prev, { ...notification, id }])
    }, [])

    const removeNotification = useCallback((id: string) => {
        setNotifications((prev) => prev.filter((notification) => notification.id !== id))
    }, [])

    useEffect(() => {
        NotificationSystem.initialize(addNotification)
    }, [addNotification])

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
            <ToastContainer notifications={notifications} onRemove={removeNotification} />
            {children}
        </NotificationContext.Provider>
    )
}
