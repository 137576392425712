import { FunctionComponent, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Icon, IconName } from '@components/base/Icon'
import { OutsideAlerter } from '@components/base/Popover'
import { AppSettingsContext } from '@lib/app/settings'
import classes from './Menu.module.scss'
import { Style } from '@lib/utils'

export interface INavigationMenuProps {
    itemGroups: INavigationMenuItemProps[][]
    isOpen: boolean
    isLoading?: boolean
    margins?: [number, number]
}

export interface INavigationMenuItemProps {
    url: string
    text: string
    icon: IconName
    isActive?: boolean
    isDisabled?: boolean
    isBusy?: boolean
    busyText?: string
    onClick?: () => void
}

export const NavigationMenu: FunctionComponent<INavigationMenuProps> = (props) => {
    const { itemGroups, isOpen, isLoading = false, margins = [0, 0] } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    const style: Style = {
        left: `${margins[0]}px`,
        top: `${margins[1]}px`,
    }

    function isLastGroup(groupIndex: number): boolean {
        return groupIndex === itemGroups.length - 1
    }

    function isEndMenuItem(groupIndex: number, itemIndex: number): boolean {
        return isLastGroup(groupIndex) && itemIndex === itemGroups[groupIndex]?.length - 1
    }

    function onMenuItemClick(callback: (() => void) | undefined): void {
        callback && callback()
    }

    return (
        <OutsideAlerter onClick={() => {}}>
            <div
                className={`${classes.navigationMenu} ${isOpen && classes.active} ${isDark && classes.navigationMenuDark}`}
                style={style}
            >
                <div className={classes.links}>
                    {isLoading ? (
                        <div className={classes.skeleton}>
                            <Icon name={IconName.Location} color='text-secondary' />
                            Loading...
                        </div>
                    ) : (
                        itemGroups.map((group, groupIdx) => (
                            <>
                                {group.map((item, itemIdx) => (
                                    <NavLink
                                        key={itemIdx + groupIdx}
                                        to={item.url}
                                        end={isEndMenuItem(groupIdx, itemIdx)}
                                        className={`${item.isActive ? classes.active : item.isDisabled || item.isBusy ? classes.disabled : ''} text-nowrap`}
                                        onClick={() => onMenuItemClick(item.onClick)}
                                    >
                                        <Icon name={item.icon} color='text-secondary' />
                                        {item.isBusy ? item.busyText : item.text}
                                    </NavLink>
                                ))}
                                {!isLastGroup(groupIdx) && <div className={classes.spacer}></div>}
                            </>
                        ))
                    )}
                </div>
            </div>
        </OutsideAlerter>
    )
}
