export function generateRandomId(length: number = 16): string {
    const hexCharacters = '0123456789abcdef'
    let randomHexId = ''

    for (let i = 0; i < length; i++) {
        randomHexId += hexCharacters[Math.floor(Math.random() * hexCharacters.length)]
    }

    return randomHexId
}
