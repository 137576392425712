import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { createApiService } from '@lib/core/api'
import { AuthContext } from '@lib/core/auth'
import { AppRoute, buildRoute, IRouterContextData } from '@lib/app/routing'
import { logoutUser } from '@lib/app/user'
import { StorageManager } from '@lib/utils'
import { config } from '@constants'
import { Loading } from '@components/base'

interface IAuthProviderProps {
    children: ReactNode
}

export const AuthProvider: FunctionComponent<IAuthProviderProps> = ({ children }) => {
    const { user, isAuthenticated, getIdTokenClaims, logout } = useAuth0()
    const [hasFetchedToken, setHasFetchedToken] = useState(false)
    const [isFetchingToken, setIsFetchingToken] = useState(false)

    const api = createApiService(config.API_BASE_URL)
    const signInUrl = `${window.location.origin}${buildRoute(AppRoute.SignIn)}`

    async function getAuthToken(): Promise<void> {
        try {
            if (user) {
                /**
                 * NOTE: When a user is logging in, we expect this call to fail because there is
                 * no session yet. However, there will be a session if the user refreshes the page
                 * while logged in, in which case we do NOT want to re-login.
                 */
                const sessionStatus = await api.auth.status()
                if (sessionStatus) {
                    setHasFetchedToken(true)
                } else {
                    const authToken = await getIdTokenClaims()
                    const canUseToken = authToken && authToken.exp && authToken.exp * 1000 > Date.now()
                    if (canUseToken) {
                        try {
                            await api.auth.login(user.email!, authToken.__raw, authToken.__raw)
                            setHasFetchedToken(true)
                        } catch (err) {
                            console.error(err)
                            /**
                             * NOTE: If the attempt to refresh the session fails, then log the user out of auth0,
                             * which will automatically redirect them to the sign-in page to redo the whole process.
                             */
                            await logoutUser(async () => logout())
                        }
                    } else {
                        const wasOnSignIn =
                            buildRoute(AppRoute.SignIn) ===
                            StorageManager.load<IRouterContextData>('routerState')?.location?.pathname
                        if (!wasOnSignIn) {
                            await logoutUser(async () => logout())
                            window.location.assign(signInUrl)
                        }
                    }
                }
            }
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        const helperFunction = async () => {
            try {
                if (isAuthenticated && !isFetchingToken && !hasFetchedToken) {
                    setIsFetchingToken(true)
                    await getAuthToken()
                }
            } catch (err) {
                console.error(err)
                window.location.reload()
            } finally {
                setIsFetchingToken(false)
            }
        }

        void helperFunction()
    }, [isAuthenticated, user])

    if (isAuthenticated && Boolean(user)) {
        return (
            <AuthContext.Provider value={{ auth: user, hasSession: hasFetchedToken }}>
                {isFetchingToken ? <Loading /> : children}
            </AuthContext.Provider>
        )
    } else {
        return (
            <AuthContext.Provider value={{ auth: user, hasSession: hasFetchedToken }}>{children}</AuthContext.Provider>
        )
    }
}
