import { ITableRowProps } from './types.ts'
import { useEffect, useRef } from 'react'

export function TableRow<T>(props: ITableRowProps<T>) {
    const { children = [], colSpan, hideBorders, MenuTemplate, onRef } = props
    const rowRef = useRef<HTMLTableRowElement>(null)

    useEffect(() => {
        onRef && rowRef && onRef(rowRef)
    }, [onRef])

    return (
        <tr className='table-row' ref={rowRef}>
            {children.map((child, index) => (
                <td
                    key={index}
                    colSpan={colSpan ?? 1}
                    className={`table-cell px-0 py-4 text-left ${hideBorders ? 'border-0' : 'border-y-[1px]'} border-[var(--stroke)] dark:border-[var(--stroke-dark)]`}
                >
                    {child}
                </td>
            ))}
            {MenuTemplate && (
                <td
                    key={children.length}
                    colSpan={colSpan ?? 1}
                    className={`table-cell px-0 py-4 ${hideBorders ? 'border-0' : 'border-y-[1px]'} border-[var(--stroke)] dark:border-[var(--stroke-dark)]`}
                >
                    <MenuTemplate />
                </td>
            )}
        </tr>
    )
}
