import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { IconName, NavigationMenu, INavigationMenuItemProps } from '@components/base'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { ProfileAvatar } from './ProfileAvatar.tsx'
import { AppRoute, buildRoute } from '@lib/app/routing'
import { logoutUser } from '@lib/app/user'

export const ProfileMenu: FunctionComponent = () => {
    const { isAuthenticated, logout, user } = useAuth0()
    const [showMenu, setShowMenu] = useState(false)
    const [initials, setInitials] = useState<string>('')
    const [isSigningOut, setIsSigningOut] = useState(false)

    const api = createApiService(config.API_BASE_URL)

    async function onSignOutClick(): Promise<void> {
        await api.auth.logout()
        await logoutUser(async () => await logout({logoutParams: { returnTo: window.location.origin }}))
        api.cache.clear()

        setShowMenu(false)
        setIsSigningOut(false)
    }

    useEffect(() => {
        if (isAuthenticated && user && user.name) {
            const firstLetter = user?.name.toUpperCase()[0]
            const secondLetter = user?.family_name ? user?.family_name[0] : user?.name.toLowerCase()[1]
            setInitials([firstLetter, secondLetter].join(''))
        }
    })

    const menuItemGroups = useMemo<INavigationMenuItemProps[][]>(
        () => [
            [
                {
                    url: buildRoute(AppRoute.Profile),
                    text: 'Profile',
                    icon: IconName.Users,
                    onClick: () => setShowMenu(false),
                },
            ],
            [
                {
                    /**
                     * NOTE: We set the URL to a hashtag so that it is effectively ignored
                     * by the underlying navigation link component (and we can perform logic
                     * in onClick before doing the navigation ourselves).
                     */
                    url: '#',
                    text: 'Sign Out',
                    icon: IconName.ThreeHorizontalLines,
                    isBusy: isSigningOut,
                    busyText: 'Signing out...',
                    onClick: () => {
                        try {
                            setIsSigningOut(true)
                            void onSignOutClick()
                        } catch (err) {
                            setIsSigningOut(false)
                            console.error(err)
                        }
                    },
                },
            ],
        ],
        [isSigningOut]
    )

    return (
        <div className='relative z-10'>
            <ProfileAvatar initials={initials} onClick={() => setShowMenu((prev) => !prev)} />
            <NavigationMenu itemGroups={menuItemGroups} isOpen={showMenu} margins={[-104, 8]} />
        </div>
    )
}
