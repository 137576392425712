import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { AppRoute, buildRoute } from '@lib/app/routing'
import { UserContext } from '@lib/app/user'
import { Icon, IconName, INavigationMenuItemProps, NavigationMenu, Text } from '@components/base'

export const SidebarNavigationMenu: FunctionComponent = () => {
    const api = createApiService(config.API_BASE_URL)

    const { data } = useContext(UserContext)

    const [isLoading, setLoading] = useState<boolean>(api.cache.projects.length == 0)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [activeProjectName, setActiveProjectName] = useState<string>('')
    const [activeProjectId, setActiveProjectId] = useState<string>('')

    const { slug } = useParams()
    const [projects, setProjects] = useState(api.cache.projects)

    useEffect(() => {
        const activeProject = projects.find((project) => project.slug === slug)
        if (activeProject) {
            setActiveProjectName(activeProject.projectName)
            setActiveProjectId(activeProject.projectId)
        }
    }, [])

    useEffect(() => {
        if (data) {
            const mapped =
                data.sites.map((site) => {
                    return {
                        projectName: site.projectName,
                        slug: `${site.projectId}`,
                        projectId: site.projectId,
                    }
                }) || []
            setLoading(false)
            setProjects(mapped)
            api.cache.setProjects(mapped)
        }
    }, [data])

    const menuItemGroups: INavigationMenuItemProps[][] = [
        [
            ...projects.map((project) => ({
                url: buildRoute(AppRoute.Project).replace(':slug', project.projectId?.toString()),
                text: project.projectName,
                icon: IconName.Location,
                isActive: activeProjectId === project.projectId,
                onClick: () => {
                    setShowMenu(false)
                    setActiveProjectId(project.projectId)
                    setActiveProjectName(project.projectName)
                },
            })),
        ],
        [
            {
                url: buildRoute(AppRoute.Overview),
                text: 'Overview',
                icon: IconName.ThreeHorizontalLines,
                onClick: () => setShowMenu(false),
            },
        ],
    ]

    return (
        <div className='relative z-10'>
            <div
                className='flex flex-row items-center justify-between px-8 py-4 cursor-pointer'
                onMouseUp={() => setShowMenu((prev) => !prev)}
            >
                <div className='flex flex-col items-start justify-start'>
                    <Text type='body2' color='text-brand-primary' freezeColor>
                        PROJECTS
                    </Text>
                    <Text type='subtitle2' color='text-primary'>
                        {activeProjectName}
                    </Text>
                </div>
                <div
                    className={`transform transition-transform duration-200 ease-in-out ${showMenu ? 'rotate-180' : 'rotate-0'}`}
                >
                    <Icon name={IconName.ChevronUp} color='text-secondary' />
                </div>
            </div>
            <NavigationMenu itemGroups={menuItemGroups} isOpen={showMenu} isLoading={isLoading} margins={[16, -8]} />
        </div>
    )
}
