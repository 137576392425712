import ReactDOM from 'react-dom/client'
import { AuthRedirectProvider } from '@components/app'
import App from './App'
import './index.scss'

ReactDOM.createRoot(document.getElementById('root')!).render(
    //<React.StrictMode>
    <AuthRedirectProvider>
        <App />
    </AuthRedirectProvider>
    //</React.StrictMode>
)
