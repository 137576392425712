import * as ExcelJs from 'exceljs'

import type { IProjectDetails } from '@lib/app/project'
import {
    DataExportFileType,
    IDataAnnotation,
    IDataExportConfiguration,
    IReadingDashboardContext,
    ISensorData,
} from './sensor.types.ts'

export async function exportDataToFile(
    fileType: DataExportFileType,
    dataSheets: unknown[][],
    configuration: IDataExportConfiguration
): Promise<void> {
    if (dataSheets.length === 0 || dataSheets.some((sheet) => sheet.length === 0)) {
        return
    } else if (configuration.sheetNames && configuration.sheetNames.length !== dataSheets.length) {
        return
    }

    if (fileType === 'csv') {
        // Separate sheets for data and annotations
        const [dataSheet, annotationsSheet] = dataSheets

        // Download Data Sheet with source label
        const dataBlob = await prepareDataBlob(fileType, [dataSheet], configuration)
        downloadBlobAsFile(fileType, dataBlob, configuration)

        // Download Annotations Sheet with source label + _annotations
        const annotationsConfiguration = configuration
        annotationsConfiguration.filename = `${configuration.filename}_annotations`
        const annotationsBlob = await prepareDataBlob(fileType, [annotationsSheet], annotationsConfiguration)
        downloadBlobAsFile(fileType, annotationsBlob, annotationsConfiguration)
    } else {
        const blob = await prepareDataBlob(fileType, dataSheets, configuration)
        downloadBlobAsFile(fileType, blob, configuration)
    }
}

async function prepareDataBlob(
    fileType: DataExportFileType,
    dataSheets: unknown[][],
    configuration: IDataExportConfiguration
): Promise<Blob> {
    const { sheetNames, useHeaderRow } = configuration
    const workbook = new ExcelJs.Workbook()

    dataSheets.forEach((sheet, idx) => {
        const sheetName = sheetNames ? sheetNames[idx] : `Sheet ${idx + 1}`
        const worksheet = workbook.addWorksheet(sheetName)
        worksheet.state = 'visible'

        const dataType = typeof sheet[0]
        sheet.forEach((item) => {
            switch (dataType) {
                case 'boolean':
                case 'number':
                case 'bigint':
                case 'string':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    worksheet.addRow([item.toString()])
                    break
                case 'object': {
                    if (Array.isArray(item)) {
                        worksheet.addRow(item.map((innerItem) => innerItem.toString()))
                    } else if (item instanceof Date) {
                        worksheet.addRow(item.toISOString())
                    } else {
                        const keys = Object.keys(item as object).filter(
                            (key) =>
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                ['boolean', 'number', 'bigint', 'string'].includes(typeof item[key]) ||
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                item[key] instanceof Date ||
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                item[key] === undefined ||
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                item[key] === null
                        )
                        if (worksheet.rowCount === 0 && useHeaderRow) {
                            worksheet.addRow(keys)
                        }

                        worksheet.addRow(
                            keys.map((key) => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                if (item[key] instanceof Date) {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    return item[key].toISOString()
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                } else if (item[key] === '' || item[key] === undefined || item[key] === null) {
                                    return 'NULL'
                                } else {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    return item[key].toString()
                                }
                            })
                        )
                    }
                    break
                }
                default:
                    break
            }
        })
    })

    switch (fileType) {
        case 'csv': {
            const buffer = await workbook.csv.writeBuffer()
            return new Blob([buffer], { type: 'text/csv;charset=utf-8;' })
        }
        case 'xlsx': {
            const buffer = await workbook.xlsx.writeBuffer()
            return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        }
        default:
            throw new Error('Unknown file type for data export')
    }
}

function downloadBlobAsFile(fileType: DataExportFileType, blob: Blob, configuration: IDataExportConfiguration): void {
    const { filename } = configuration
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `${filename}.${fileType}`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
}

export function getSensorDataFromDashboardContext(context: IReadingDashboardContext, unit: string): ISensorData {
    return {
        timestamp: new Date(context.timestamp),
        value: context.value,
        address: context.address,
        annotations: Array.from(Object.values(context.annotations)),
        score: parseInt((context.score * 100).toFixed()),
        unit,
    }
}

export function getNumberOfSensorsOnline(project: IProjectDetails): number {
    let online = 0
    Object.entries(project.sensors.sensors).forEach(([_, sensor]) => {
        if (
            Object.entries(sensor.readings).some(([_, reading]) => {
                // Determine the offset based on the equipment group
                const offset = sensor.equipment.group === 'Manual' ? 60 * 24 : 60

                try {
                    // Parse the timestamp and calculate the time difference
                    const timestamp = new Date(reading.timestamp)
                    const now = new Date()
                    const timeDifferenceInMinutes = now.getMinutes() - timestamp.getMinutes() // Convert milliseconds to minutes

                    // Check if the time difference is within the offset
                    if (timeDifferenceInMinutes < offset) {
                        return true
                    }
                } catch (e) {
                    // Handle parsing errors (if timestamp is invalid)
                    return false
                }
            })
        ) {
            online++
        }
    })

    return online
}

export function sortSensorData(data: ISensorData[]): ISensorData[] {
    return Array.from(data.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()))
}

export function sortAnnotations(data: IDataAnnotation[]): IDataAnnotation[] {
    return Array.from(data.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()))
}

export function mapSensorDataToAnnotations(data: ISensorData[]): (IDataAnnotation & { readingId: string })[] {
    return data.flatMap((item) =>
        sortAnnotations(item.annotations).map((annotation) => {
            /**
             * NOTE: To make it clearer that the "key" is the ID of the sensor reading, we change the
             * property name here.
             */
            const { key, ...remainingAnnotationData } = annotation
            return {
                ...remainingAnnotationData,
                readingId: key,
            } as IDataAnnotation & { readingId: string }
        })
    )
}
