import { useEffect, useState } from 'react'
import { ProfileLayout } from '@components/app'
import { Button, Pane, Text } from '@components/base'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'

export const IdentityPage = () => {
    const api = createApiService(config.API_BASE_URL)

    const [_, setLoading] = useState(true)
    const [identity, setIdentity] = useState<any | null>(null)
    const [message, setMessage] = useState<string>('')
    const [deleteDid, setDeleteDid] = useState(false)

    const getIdentity = async () => {
        try {
            const response = await api.user.getIdentity()
            if (response && response['doc_id']) {
                setMessage('')
                setIdentity(response['doc_id'])
            }
        } catch (err) {
            setMessage(err?.toString() ?? String(err))
            setDeleteDid(true)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        void getIdentity()
    }, [])

    const onCreateClick = async () => {
        setMessage('Processing Request... Please wait')
        await api.user
            .createIdentity()
            .then((response) => {
                // Clear messages as we will display identity instead
                setMessage('')
                setIdentity(response)
            })
            .catch((error) => {
                // Set create error (Usually means requesting balance and click again)
                setMessage(error.toString())
            })
    }

    const onDeleteClick = async () => {
        setMessage('Processing Request... Please wait')
        await api.user
            .deleteIdentity()
            .then((message) => {
                // Set delete successfull message
                setMessage(message)
                setDeleteDid(false)
                setIdentity(null)
            })
            .catch((error) => {
                // Set delete error message
                setMessage(error.toString())
            })
    }

    return (
        <ProfileLayout title='Identity'>
            <Pane>
                {identity && (
                    <div id='identityContainer' className='flex flex-col space-y-4'>
                        <Text type='body1'>Found identity:</Text>
                        <Text type='body1'>{identity.toString()}</Text>
                        <div>
                            <Button size='large' onClick={onDeleteClick}>
                                Delete
                            </Button>
                        </div>
                    </div>
                )}
                {!identity && (
                    <div id='createIdentityContainer' className='flex flex-col space-y-4'>
                        <Text type='header3'>Create an Identity</Text>
                        {!deleteDid && (
                            <Button size='large' onClick={onCreateClick}>
                                Create
                            </Button>
                        )}
                        {deleteDid && (
                            <Button size='large' onClick={onDeleteClick}>
                                Delete
                            </Button>
                        )}
                        <Text type='body2'>{message}</Text>
                    </div>
                )}
            </Pane>
        </ProfileLayout>
    )
}
