import { FunctionComponent, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '@lib/core/auth'
import { AppRoute, buildRoute, IRouterContextData } from '@lib/app/routing'
import { StorageManager } from '@lib/utils'
import { Loading } from '@components/base'

export const RootPage: FunctionComponent = () => {
    const { hasSession } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        const routerState = StorageManager.load<IRouterContextData>('routerState')
        if (hasSession) {
            const fallbackRoute = buildRoute(AppRoute.Overview)
            const route = routerState?.location?.pathname ?? fallbackRoute
            navigate(route)
        } else {
            /**
             * NOTE: Because we clear the persisted router state on logout, if we see that
             * it exists despite not having a session then it means that the user refreshed
             * the page.
             */
            const persistedPath = routerState?.location?.pathname ?? ''
            const shouldNavigateToSignIn =
                persistedPath === '' || [buildRoute(AppRoute.Root), buildRoute(AppRoute.SignIn)].includes(persistedPath)
            if (shouldNavigateToSignIn) {
                navigate(buildRoute(AppRoute.SignIn))
            } else {
                navigate(persistedPath)
            }
        }
    }, [hasSession])

    return <Loading />
}
