import { FunctionComponent, useContext, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { OnboardingLayout } from '@components/app'
import { Button, Link, Text } from '@components/base'
import { StorageManager } from '@lib/utils'
import { AppRoute, buildRoute, IRouterContextData } from '@lib/app/routing'
import { AuthContext } from '@lib/core/auth'
import { useNavigate } from 'react-router-dom'

export const SignInPage: FunctionComponent = () => {
    const { loginWithRedirect } = useAuth0()
    const { hasSession } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (hasSession) {
            const routerState = StorageManager.load<IRouterContextData>('routerState')
            const wasOnSignIn = routerState?.location?.pathname === buildRoute(AppRoute.SignIn)
            const fallbackRoute = buildRoute(AppRoute.Overview)
            const route = wasOnSignIn ? fallbackRoute : (routerState?.location?.pathname ?? fallbackRoute)
            navigate(route)
        }
    }, [hasSession])

    return (
        <OnboardingLayout>
            <Text type='header1' color='text-primary'>
                Sign in to access the dashboard
            </Text>
            <div className='mt-6'>
                <Button size='large' width='full' onClick={() => loginWithRedirect()}>
                    Sign In
                </Button>
                <div className='mt-2'>
                    <Text type='body1' color='text-primary'>
                        Don't have an account?
                    </Text>
                    <Link url='/register'>Request access here</Link>
                </div>
            </div>
        </OnboardingLayout>
    )
}
