import { MS_PER_SECOND } from '@lib/utils'
import { INotification } from './notification.types.ts'

export const DEFAULT_NOTIFICATION_DURATION: number = 4 * MS_PER_SECOND

export const DEFAULT_NOTIFICATION: INotification = {
    id: '',
    type: 'info',
    message: '',
    duration: DEFAULT_NOTIFICATION_DURATION,
    dismissible: true,
    persistent: false,
}
