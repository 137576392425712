import { getNestedReadingValue, IReadingDashboardContext } from '@lib/app/sensor'
import { IChartData } from '@components/base/Charts/types.ts'

export { AddProjectDataSourcePage } from '@pages/Project/ProjectDataSources/AddProjectDataSource/AddProjectDataSourcePage.tsx'
export { ProjectDataSourcePage } from '@pages/Project/ProjectDataSources/ProjectDataSource/ProjectDataSourcePage.tsx'

export { ProjectDataSourcesPage } from './ProjectDataSourcesPage'

export function transformReadings(readings: { [key: string]: IReadingDashboardContext }) {
    const result: { [key: string]: { [subKey: string]: IReadingDashboardContext }[] } = {}

    for (const key in readings) {
        if (Object.prototype.hasOwnProperty.call(readings, key)) {
            const reading = readings[key]
            const splitKey = key.split(':')

            if (splitKey.length === 1) {
                if (!result['_']) {
                    result['_'] = []
                }
                // Unbroken key case, store under '_'
                result['_'].push({ [key]: reading })
            } else {
                // Broken key case, store under the first part of the split
                const mainKey = splitKey[0]
                const subKey = splitKey[1]

                if (!result[mainKey]) {
                    result[mainKey] = []
                }

                result[mainKey].push({ [subKey]: reading })
            }
        }
    }

    return result
}

export function sortNestedReadings(
    nestedReadings: { [key: string]: { [subKey: string]: IReadingDashboardContext }[] },
    contextEquipment: string
): Array<{ equipment: string; data: IChartData[] }> {
    return Object.keys(nestedReadings).length === 1 && Object.keys(nestedReadings)[0] === '_'
        ? [
              {
                  equipment: contextEquipment,
                  data: nestedReadings['_']
                      .map((v) => {
                          return Object.values(v).map((data) => {
                              const [valueType, value] = getNestedReadingValue(data.value)

                              return {
                                  name: new Date(data.timestamp),
                                  uv: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  pv: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  amt: valueType === 'Float' || valueType === 'Int' ? value : 0,
                              }
                          })
                      })
                      .flat(),
              },
          ]
        : Object.entries(nestedReadings)
              .map((v) => {
                  const [equipment, nested] = v
                  let unit = nested[0][Object.keys(nested[0])[0]].unit
                  unit = unit ? unit.replace('[', '').replace(']', '') : ''
                  const data = Object.values(nested)
                      .map((n) => {
                          return Object.values(n).map((data) => {
                              const [valueType, value] = getNestedReadingValue(data.value)

                              return {
                                  name: new Date(data.timestamp),
                                  uv: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  pv: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  amt: valueType === 'Float' || valueType === 'Int' ? value : 0,
                                  unit: data.unit,
                              }
                          })
                      })
                      .flat()

                  return {
                      equipment: equipment === '_' ? contextEquipment : equipment,
                      unit,
                      data,
                  }
              })
              .flat()
}
