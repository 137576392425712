export interface IAnalyticsProfile {
    id: string
    calculationInterval: IAnalyticsCalculationInterval
    calculations: IAnalyticsCalculation[]
}

export interface IAnalyticsCalculationInterval {
    secs: number
    nanos: number
}

export interface IAnalyticsConfiguration {
    calculations: AnalyticsConfigurationCalculations
}

export type AnalyticsConfigurationCalculations = Record<string, boolean>

export interface IAnalyticsCalculation {
    id: string
    text: string
    equation: string
    parameters: AnalyticsParameter[]
}

export type AnalyticsParameter = IStaticParameter | IInputParameter | ICalculationParameter

export function isStaticParameter(parameter: AnalyticsParameter): parameter is IStaticParameter {
    return (parameter as IStaticParameter).Static?.value !== undefined
}

export function isInputParameter(parameter: AnalyticsParameter): parameter is IInputParameter {
    return (parameter as IStaticParameter).Static?.value === undefined
}

export function isCalculationParameter(parameter: AnalyticsParameter): parameter is ICalculationParameter {
    return (parameter as ICalculationParameter).Calculation?.id !== undefined
}

interface IStaticParameter {
    Static: {
        id: string
        unit: string
        text: string
        value: number
    }
}

interface IInputParameter {
    Input: {
        id: string
        unit: string
        text: string
        label?: string
    }
}

interface ICalculationParameter {
    Calculation: {
        id: string
        unit: string
        text: string
    }
}
