import { FunctionComponent, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton } from '../Button'
import { IconName } from '@components/base/Icon'
import { SearchInput } from '@components/base/Inputs'
import { ProfileMenu } from '@components/app/Profile/ProfileMenu'
import { AppSettingsContext } from '@lib/app/settings'
import { Theme, toggleHtmlRootDarkMode } from '@lib/utils'
import classes from './Header.module.scss'

export const Header: FunctionComponent = () => {
    const { data, setData } = useContext(AppSettingsContext)
    const navigate = useNavigate()

    const { slug } = useParams()

    function onThemeClick(theme: Theme): void {
        toggleHtmlRootDarkMode()
        setData({
            ...data,
            theme: theme,
        })
    }

    function onNotificationsClick(): void {
        const url = `/projects/${slug}/activity`
        navigate(url)
    }

    return (
        <header
            className={`${classes.header} ${data.theme === 'dark' && classes.headerDark} flex flex-row items-center justify-end px-8 py-4 border-b-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)]`}
        >
            <div className='flex flex-row space-x-4 items-center justify-between'>
                {data.theme === 'light' && <IconButton icon={IconName.Sun} onClick={() => onThemeClick('dark')} />}
                {data.theme === 'dark' && <IconButton icon={IconName.Moon} onClick={() => onThemeClick('light')} />}
                {Boolean(slug) && <IconButton icon={IconName.Notifications} onClick={onNotificationsClick} />}
                <SearchInput onChange={() => null}></SearchInput>
            </div>
            <div className='ml-6'>
                <ProfileMenu />
            </div>
        </header>
    )
}
