import { UserRole } from './user.enums.ts'
import { StorageManager } from '@lib/utils'

export function getUserRoleText(role: UserRole): string {
    switch (role) {
        case UserRole.Admin:
            return 'Admin'
        case UserRole.Viewer:
        default:
            return 'Viewer'
    }
}

export async function logoutUser(logoutFn?: () => Promise<void>): Promise<void> {
    StorageManager.delete('routerState')
    logoutFn && (await logoutFn())
}
