import { IFile } from './file'

export enum AssetType {
    Profile = 'Profile',
    Site = 'Site',
    Sensor = 'Sensor',
    Equipment = 'Equipment',
    Custom = 'Custom',
    Link = 'Link',
}

export interface IAsset {
    asset: {
        type: AssetType
        name: string
    }
    file: IFile
}

export interface IListAssetsContext {
    files: IAsset[]
}
