import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectDataSourcesTable, ProjectLayout } from '@components/app'
import { dateToReadable } from '@lib/utils'
import { ISensorSource } from '@lib/app/sensor'
import { Button, IconName, Text } from '@components/base'
import { buildProjectRoute, ProjectDataSourcesRoute, ProjectRoute } from '@lib/app/routing'
import { ProjectContext } from '@lib/app/project'
import { FEATURES } from '@features'

export const ProjectDataSourcesPage = () => {
    const { slug } = useParams()
    const navigate = useNavigate()

    const { details } = useContext(ProjectContext).data

    const [sensors, setSensors] = useState<ISensorSource[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (details) {
            const mappedSensors = Object.values(details.sensors.sensors).map((sensor) => {
                return {
                    source: sensor.equipment.name,
                    url: sensor.id,
                    inputType: sensor.equipment.eqType,
                    timestamp: dateToReadable(new Date(sensor.lastUpdated)),
                    dataConfidence: Math.round(sensor.avgcf * 100) / 100,
                    // Sensor / Sheet
                    dataType: sensor.equipment.group == 'Manual' ? 'Sheet' : 'Sensor',
                    // Manual / Automatic
                    dataEntry: sensor.equipment.group == 'Manual' ? 'Manual' : 'Automated',
                    recordCount: Object.keys(sensor.readings).length,
                }
            })
            setSensors(mappedSensors)
            setLoading(false)
        }
    }, [details])

    function onAddNewSourceClick(): void {
        navigate(
            buildProjectRoute(ProjectRoute.DataSources, ProjectDataSourcesRoute.AddSensor).replace(':slug', slug ?? '')
        )
    }

    const TitleComponent = () => {
        return (
            <>
                <Text type='header2'>Data Sources</Text>
                {FEATURES.project.dataSources.addSource.enabled && (
                    <Button size='medium' icon={IconName.Plus} onClick={onAddNewSourceClick}>
                        Add New Source
                    </Button>
                )}
            </>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <ProjectDataSourcesTable loading={loading} sensors={sensors} />
        </ProjectLayout>
    )
}
