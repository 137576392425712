import { FunctionComponent, ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { config } from '@constants'

interface IAuthRedirectProviderProps {
    children: ReactNode
}

export const AuthRedirectProvider: FunctionComponent<IAuthRedirectProviderProps> = ({ children }) => {
    return (
        <Auth0Provider
            domain={config.AUTH0_DOMAIN}
            clientId={config.AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}
            useRefreshTokens={true}
            cacheLocation={'localstorage'}
        >
            {children}
        </Auth0Provider>
    )
}
