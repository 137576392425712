import { IProjectFeatures } from './interfaces/project-features.interface.ts'

export const PROJECT_FEATURES: IProjectFeatures = {
    dashboard: {
        enabled: true,
    },
    analytics: {
        enabled: true,
        addCalculation: {
            enabled: false,
        },
        customizeParameters: {
            enabled: false,
        },
    },
    dataSources: {
        enabled: true,
        addSource: {
            enabled: false,
        },
        exportData: {
            enabled: true,
            csv: {
                enabled: true,
            },
            xlsx: {
                enabled: true,
            },
        },
    },
    documentation: {
        enabled: true,
    },
    activity: {
        enabled: true,
    },
    users: {
        enabled: false,
    },
    guardian: {
        enabled: true,
        report: {
            enabled: false,
        },
    },
}
