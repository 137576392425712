import { FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { AuthContext } from '@lib/core/auth'
import { IContext } from '@lib/core/context'
import { IUser, IUserContextData, logoutUser, UserContext } from '@lib/app/user'
import { isObjectEmpty, MS_PER_SECOND } from '@lib/utils'

// const INITIAL_OVERVIEW_POLL_INTERVAL_MS = 6 * MS_PER_SECOND
const SUBSEQUENT_OVERVIEW_POLL_INTERVAL_MS = 30 * MS_PER_SECOND

interface IUserProviderProps {
    children: ReactNode
}

export const UserProvider: FunctionComponent<IUserProviderProps> = (props) => {
    const { children } = props
    const { hasSession, auth } = useContext(AuthContext)

    const api = createApiService(config.API_BASE_URL)

    const [userData, setUserData] = useState<IUserContextData>({ user: auth as IUser, sites: [] })
    const userContext: IContext<IUserContextData> = {
        data: userData,
        setData: onUserDataUpdate,
    }

    function onUserDataUpdate(payload: Partial<IUserContextData>): void {
        setUserData({
            ...userData,
            ...payload,
        })
    }

    let pollingInterval: number | null = null

    async function updateOverview(): Promise<void> {
        try {
            const overview = await api.context.getProjects()
            if (overview && 'sites' in overview) {
                if (userContext.data.sites !== overview.sites) {
                    userContext.data.sites = overview.sites
                    setUserData({
                        ...userData,
                        sites: overview.sites,
                    })
                }
            } else {
                if (isObjectEmpty(userData.user) && pollingInterval) {
                    clearInterval(pollingInterval)
                }

                await logoutUser()
                window.location.assign(window?.location?.origin ?? '')
            }
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (hasSession && !pollingInterval) {
            void updateOverview()
            pollingInterval = setInterval(() => {
                void updateOverview()
            }, SUBSEQUENT_OVERVIEW_POLL_INTERVAL_MS) as unknown as number
        } else {
            if (pollingInterval) {
                clearInterval(pollingInterval)
            }
        }

        return () => {
            pollingInterval && clearInterval(pollingInterval)
        }
    }, [hasSession])

    return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
}
