import { BaseApiService } from '@lib/core/api'
import { IHederaProfile } from '@lib/app/hedera'
import { IUserSubscriptionInfo } from './user.types'

export class UserApiService extends BaseApiService {
    constructor(baseUrl: string) {
        super(baseUrl)
    }

    async getIdentity(): Promise<any> {
        return this.get('api/user/identity')
    }

    async createIdentity() {
        return this.post('api/user/identity/create', {})
    }

    async deleteIdentity() {
        return this.post('api/user/identity/delete', {})
    }

    async profile() {
        return this.getIdentity()
    }

    async hederaProfile(): Promise<IHederaProfile> {
        return this.get('api/guardian/hedera/profile')
    }
    async hederaLink(username: string, password: string) {
        return this.post('api/guardian/hedera/link', { username, password })
    }

    async getSubscription(customerId: string): Promise<IUserSubscriptionInfo[]> {
        return this.post('api/user/subscriptions', { customerId })
    }

    async cancelSubscription(subscriptionId: string) {
        return this.post('api/user/subscriptions/cancel', { subscriptionId })
    }
}
