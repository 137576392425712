import { FunctionComponent, useEffect } from 'react'
import { TooltipProps } from 'recharts'
import { Text } from '@components/base/Text'
import { formatDate } from '@lib/utils'
import { IChartTooltipMetadata } from '@components/base/Charts/types.ts'

interface ITooltipProps extends TooltipProps<string, string> {
    isRange?: boolean
    onNewTooltip?: (data: IChartTooltipMetadata[]) => void
}

export const CustomTooltip: FunctionComponent<ITooltipProps> = (props) => {
    const { isRange = false, payload = [], label, active, onNewTooltip } = props

    useEffect(() => {
        onNewTooltip && onNewTooltip(payload as unknown as IChartTooltipMetadata[])
    }, [payload])

    if (active && payload.length > 0) {
        return (
            <div className='px-6 py-4 text-center shadow-md bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)]'>
                <Text type='subtitle2' color='text-primary'>
                    {formatDate(label)}
                </Text>
                {isRange ? (
                    <>
                        <Text type='header3' color='text-primary'>
                            Min: {payload[0].payload.uv}
                        </Text>
                        <Text type='header3' color='text-primary'>
                            Max: {payload[0].payload.pv}
                        </Text>
                    </>
                ) : (
                    <Text type='header3' color='text-primary'>
                        {payload[0].value as string}
                    </Text>
                )}
            </div>
        )
    }
    return null
}
