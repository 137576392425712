import { useLocation, useParams } from 'react-router-dom'
import { cargoAverage, cargoCondidence, cargoCurrent, grapeHarvest, greenWaste, sheet } from '@assets'
import { FunctionComponent, useEffect, useState } from 'react'
import { dateToReadable } from '@lib/utils'
import { ProjectDataSourceImage, ProjectLayout, ProjectSensorDataTable } from '@components/app'
import { BarChart, Pane, Skeleton, Tabs, Text } from '@components/base'
import {
    exportDataToFile,
    getSensorDataFromDashboardContext,
    ISensorContextData,
    ISensorData,
    mapSensorDataToAnnotations,
    NestedReadingValue,
    sortSensorData,
} from '@lib/app/sensor'
import { ProjectDataSourceTitle } from '../ProjectDataSourceTitle'
import { IChartData } from '@components/base/Charts/types.ts'
import { sortNestedReadings, transformReadings } from '@pages'
import { PublicAssetPath } from '@pages/Project/ProjectDataSources/ProjectDataSource/Sources/index.ts'

const cargoDataEmpty: Array<{
    img: string
    text: string
    value: NestedReadingValue
    valueUnit: string
}> = [
    {
        img: cargoCurrent,
        text: 'Current Load',
        value: { type: 'Empty' },
        valueUnit: 'Kg',
    },
    {
        img: cargoAverage,
        text: 'Daily Average Load',
        value: { type: 'Empty' },
        valueUnit: 'Kg',
    },
    {
        img: cargoCondidence,
        text: 'Data Confidence',
        value: { type: 'Empty' },
        valueUnit: '%',
    },
]

export const CargoDataSource: FunctionComponent<{ sensorContext: ISensorContextData }> = (props) => {
    const [activeTab, setActiveTab] = useState(0)

    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    const { sensorContext } = props
    const imageUri =
        PublicAssetPath + (sensorContext?.assetUrl || 'assets/' + slug + '/sensor.' + sensorContext?.equipment.name)

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [cargoData, setCargoData] = useState<
        Array<{
            img: string
            text: string
            value: NestedReadingValue
            valueUnit: string
        }>
    >([{ ...cargoDataEmpty[0] }, { ...cargoDataEmpty[1] }, { ...cargoDataEmpty[2] }])

    const [tableList, setTableList] = useState<{ [key: string]: ISensorData[] }>({})
    const [graphData, setGraphData] = useState<Array<{ equipment: string; data: IChartData[] }>>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensorContext) {
            const sorted = transformReadings(sensorContext.readings)
            const newGraphData = sortNestedReadings(sorted, sensorContext.equipment.name)
            setGraphData(newGraphData)

            const tableList: { [key: string]: ISensorData[] } = {}
            Object.entries(sorted).forEach((v) => {
                const [key, context] = v
                tableList[key] = Object.values(context)
                    .map((v) => {
                        const readings = Array.from(Object.values(v)).map((v) => {
                            const unit = v.unit ? v.unit : sensorContext.equipment.units
                            return getSensorDataFromDashboardContext(v, unit)
                        })

                        readings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())

                        return readings
                    })
                    .flat()
            })

            setTableList(tableList)
            setLoading(false)
        }
    }, [sensorContext])

    useEffect(() => {
        if (sensorContext) {
            const numbers = [...cargoData]
            numbers[0].value = { type: 'Float', value: Math.round(props.sensorContext.state.realTimeFlow) }
            numbers[0].valueUnit = props.sensorContext.equipment.units
            numbers[1].value = { type: 'Float', value: Math.round(props.sensorContext.state.currentDayAvg) }
            numbers[1].valueUnit = props.sensorContext.equipment.units
            numbers[2].value = { type: 'Float', value: Math.round(props.sensorContext.avgcf) }
            setCargoData(numbers)
        }
    }, [sensorContext])

    const TitleComponent = () => {
        return (
            <ProjectDataSourceTitle
                title={sensorContext?.equipment.name ?? 'Cargo'}
                url={url}
                canShowMenu={activeTab === 1}
                onExportClick={async (fileType) => {
                    const sortedSensorData = sortSensorData(
                        Object.entries(sensorContext?.readings ?? []).map(([key, value]) => ({
                            id: key,
                            ...getSensorDataFromDashboardContext(value, sensorContext?.equipment.units ?? ''),
                        }))
                    )
                    await exportDataToFile(fileType, [sortedSensorData, mapSensorDataToAnnotations(sortedSensorData)], {
                        filename: sensorContext?.id ?? 'Cargo',
                        sheetNames: ['Readings', 'Annotations'],
                        useHeaderRow: true,
                    })
                }}
            />
        )
    }

    const imageAlt =
        sensorContext?.id === 'Grape Harvest'
            ? 'Grape Harvest Image'
            : sensorContext?.id === 'Digestors 1 + 2'
              ? 'Green Waste Image'
              : 'Sheet Placeholder'
    const defaultImage =
        sensorContext?.id === 'Grape Harvest'
            ? grapeHarvest
            : sensorContext?.id === 'Digestors 1 + 2'
              ? greenWaste
              : sheet

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']} onTabClick={setActiveTab}>
                <article className='flex flex-col space-y-8'>
                    {graphData.length < 2 && (
                        <div className='flex flex-row items-center justify-between space-x-4 p-8 clipped bg-surface-transparent dark:bg-surface-transparent-dark'>
                            <div className='min-h-[300px]'>
                                <ProjectDataSourceImage uri={imageUri} alt={imageAlt} defaultImage={defaultImage} />
                            </div>
                            <div className='w-full flex flex-col space-y-4 justify-between'>
                                <div className='w-full flex flex-row items-center justify-between space-x-4'>
                                    {cargoData.map((data, index) => (
                                        <div
                                            className='w-1/3 h-full bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-col items-center justify-between p-6 space-y-4'
                                            key={index}
                                        >
                                            <img src={data.img} alt='Image' />
                                            <Text type='body1' color='text-secondary'>
                                                {data.text}
                                            </Text>
                                            <h5 className='font-32-28-500 text-text-primary dark:text-text-primary-dark'>
                                                {data.value.type == 'Float' || data.value.type == 'Int'
                                                    ? data.value.value
                                                    : 0}{' '}
                                                <span className='font-24-28-400 text-[var(--text-secondary)]'>
                                                    {data.valueUnit}
                                                </span>
                                            </h5>
                                        </div>
                                    ))}
                                </div>
                                <div className='w-full bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] p-6'>
                                    <Text type='header3'>Solid Waste Load</Text>
                                    <div className='w-full flex flex-row items-center justify-between mt-6'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <Text type='body1' color='text-secondary'>
                                                Source:
                                            </Text>
                                            <Text type='subtitle1'>
                                                {sensorContext?.equipment.id || <Skeleton width={64} />}
                                            </Text>
                                        </div>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <Text type='body1' color='text-secondary'>
                                                Last updated:
                                            </Text>
                                            <Text type='subtitle1'>
                                                {sensorContext ? (
                                                    dateToReadable(new Date(sensorContext.lastUpdated))
                                                ) : (
                                                    <Skeleton width={64} />
                                                )}
                                            </Text>
                                        </div>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <Text type='body1' color='text-secondary'>
                                                Units:
                                            </Text>
                                            <Text type='subtitle1'>
                                                {sensorContext?.equipment.units || <Skeleton width={64} />}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Pane>
                        {Object.entries(graphData).map((v) => {
                            const [_, nested] = v
                            return (
                                <div className='p-2'>
                                    <BarChart
                                        title={{ title: nested.equipment }}
                                        data={nested.data}
                                        loading={!sensorContext}
                                    />
                                </div>
                            )
                        })}
                    </Pane>
                </article>
                {Object.entries(tableList).map((v) => {
                    const [key, value] = v
                    return <ProjectSensorDataTable title={key} loading={loading} sensorDataList={value} />
                })}
            </Tabs>
        </ProjectLayout>
    )
}
