import { FunctionComponent } from 'react'
import { ProgressBar } from '@components/base'
import { Link } from '@components/base/Link'
import { ITableRowProps, TableRow } from '@components/base/Table'
import { Text } from '@components/base/Text'
import { ISensorSource } from '@lib/app/sensor'
import { ProjectDataSourcesTableMenu } from './ProjectDataSourcesTableMenu.tsx'

export const ProjectDataSourcesTableRow: FunctionComponent<ITableRowProps<ISensorSource>> = (props) => {
    const { url, source, inputType, dataConfidence, dataType, dataEntry, recordCount, timestamp } = props?.data ?? {}
    const cells = [
        <Link url={url ?? ''}>{source}</Link>,
        <Text type='body1'>{inputType}</Text>,
        <ProgressBar progress={dataConfidence ?? 0} />,
        <Text type='body1'>{dataType}</Text>,
        <Text type='body1'>{dataEntry}</Text>,
        <Text type='body1'>{recordCount}</Text>,
        <Text type='body1'>{timestamp}</Text>,
    ]

    return <TableRow MenuTemplate={ProjectDataSourcesTableMenu}>{cells.map((cell) => cell)}</TableRow>
}
