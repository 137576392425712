import { useEffect, useMemo, useState } from 'react'
import { Pane } from '@components/base/Pane'
import { SortDirection, sortPrimitives } from '@lib/utils'
import { EmptyTableRow, LoadingTableRow, TableFooter, TableHeader } from './components'
import { ITableProps, ITableSortConfig } from './types.ts'

const DEFAULT_TABLE_SORT_CONFIG: ITableSortConfig = {
    direction: 'desc',
    key: null,
}

export function Table<T>({
    fields,
    data,
    RowTemplate,
    TitleTemplate,
    loading,
    rowsPerPage = 10,
    rowTemplateProps = {},
    disableSorting = false,
    disableFooter = false,
    enableMenus = false,
    unClipped = false,
}: ITableProps<T>) {
    const [currentPage, setCurrentPage] = useState(1)
    const [sortConfig, setSortConfig] = useState(DEFAULT_TABLE_SORT_CONFIG)

    useEffect(() => {
        if (!disableSorting && data.length > 0) {
            const key = fields.find((field) => field.sortByDefault)?.key ?? fields[0].key
            setSortConfig({ key: key.toString(), direction: sortConfig.direction })
        }
    }, [])

    const colSpan = enableMenus ? fields.length + 1 : fields.length

    const sortedData = useMemo(() => {
        const { key, direction } = sortConfig
        if (key) {
            const { sortingFunction } = fields.find((field) => field.key === key) ?? {}
            return [...data].sort((a, b) => sortingFunction?.(a, b, direction) ?? 0)
        } else {
            const key = fields.find((field) => field.sortByDefault)?.key ?? fields[0].key
            return [...data].sort((a, b) => sortPrimitives(a[key as keyof T], b[key as keyof T]))
        }
    }, [data, sortConfig])

    function onSortClick(key: keyof T): void {
        if (!disableSorting) {
            let direction: SortDirection = 'asc'
            if (sortConfig.key === key && sortConfig.direction === 'asc') {
                direction = 'desc'
            }
            setSortConfig({ key: key.toString(), direction })
        }
    }

    const totalEntries = sortedData.length
    const totalPages = Math.ceil(totalEntries / rowsPerPage)
    const currentDataChunk = useMemo(
        () => sortedData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
        [currentPage, sortedData]
    )
    const hasNoData = totalEntries === 0

    function onPreviousPageClick(): void {
        setCurrentPage((prev) => Math.max(prev - 1, 1))
    }

    function onNextPageClick(): void {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
    }

    function onGoToPageClick(pageNumber: number): void {
        setCurrentPage(pageNumber)
    }

    function shouldDisplayAboveRow(index: number): boolean {
        return index >= Math.floor(rowsPerPage / 2)
    }

    return (
        <Pane padding='top' unClipped={unClipped}>
            {TitleTemplate && <TitleTemplate loading={loading} />}
            <table className='table w-full'>
                {/* Header */}
                <TableHeader
                    fields={fields}
                    dataCount={data.length}
                    sortConfig={sortConfig}
                    onSortClick={(key: keyof T) => onSortClick(key)}
                    disableSorting={disableSorting}
                    hasGhostCell={enableMenus}
                />

                {/* Body */}
                <tbody
                    className={`w-full bg-transparent ${hasNoData ? 'border-t-[1px]' : 'border-y-[1px]'} border-[var(--stroke)] dark:border-[var(--stroke-dark)]`}
                >
                    {!hasNoData ? (
                        currentDataChunk.map((data, idx) => {
                            return RowTemplate({ data, displayAbove: shouldDisplayAboveRow(idx), ...rowTemplateProps })
                        })
                    ) : loading ? (
                        <LoadingTableRow colSpan={colSpan} />
                    ) : (
                        <EmptyTableRow colSpan={colSpan} />
                    )}
                </tbody>

                {/* Footer */}
                {!disableFooter && !hasNoData && (
                    <TableFooter
                        results={{ length: sortedData.length, limit: rowsPerPage, page: currentPage }}
                        colSpan={colSpan}
                        onPreviousPageClick={onPreviousPageClick}
                        onNextPageClick={onNextPageClick}
                        onGoToPageClick={onGoToPageClick}
                    />
                )}
            </table>
        </Pane>
    )
}
