import { FunctionComponent } from 'react'
import { ITableField, Table } from '@components/base/Table'
import { ISensorSource } from '@lib/app/sensor'
import { SortDirection, sortPrimitives } from '@lib/utils'
import { ProjectDataSourcesTableRow } from './ProjectDataSourcesTableRow.tsx'

interface IProjectDataSourcesTableProps {
    loading: boolean
    sensors: ISensorSource[]
}

const PROJECT_DATA_SOURCES_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Source',
        key: 'source',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.source, b.source, direction),
        sortByDefault: true,
    },
    {
        text: 'Input Type',
        key: 'inputType',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.inputType, b.inputType, direction),
    },
    {
        text: 'Data Confidence',
        key: 'dataConfidence',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.dataConfidence, b.dataConfidence, direction),
    },
    {
        text: 'Data Type',
        key: 'dataType',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.dataType, b.dataType, direction),
    },
    {
        text: 'Data Entry',
        key: 'dataEntry',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.dataEntry, b.dataEntry, direction),
    },
    {
        text: 'Records',
        key: 'recordCount',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.recordCount, b.recordCount, direction),
    },
    {
        text: 'Last Updated',
        key: 'lastUpdated',
        sortingFunction: (a: ISensorSource, b: ISensorSource, direction: SortDirection) =>
            sortPrimitives(a.timestamp, b.timestamp, direction),
    },
]

export const ProjectDataSourcesTable: FunctionComponent<IProjectDataSourcesTableProps> = (props) => {
    const { loading, sensors } = props
    return (
        <Table
            fields={PROJECT_DATA_SOURCES_TABLE_FIELDS}
            data={sensors}
            RowTemplate={ProjectDataSourcesTableRow}
            loading={loading}
            enableMenus
        />
    )
}
