import { FunctionComponent } from 'react'
import { IActionMenuItemProps, ActionMenu } from '@components/base/Menu'

const PROJECT_DATA_SOURCES_TABLE_MENU_ITEMS: IActionMenuItemProps[] = [
    {
        title: 'Edit',
        onClick: () => {
            /* eslint-disable no-console */
            console.log('Edit data source')
        },
    },
    {
        title: 'Remove',
        onClick: () => {
            /* eslint-disable no-console */
            console.log('Remove data source')
        },
        variant: 'danger',
    },
]

export const ProjectDataSourcesTableMenu: FunctionComponent = () => {
    return <ActionMenu items={PROJECT_DATA_SOURCES_TABLE_MENU_ITEMS} />
}
