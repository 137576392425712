import { FunctionComponent, useMemo, useState } from 'react'
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, BarChart as _BarChart, Bar } from 'recharts'
import { CustomTooltip } from './CustomTooltip.tsx'
import { IChartProps } from './types.ts'
import {
    cleanChartData,
    collapseChartData,
    filterChartDataByDate,
    getNewestChartData,
    getOldestChartData,
} from './utils.ts'
import { Text } from '../Text'
import { formatDate, MS_PER_DAY, MS_PER_MONTH, MS_PER_YEAR, truncateString } from '@lib/utils'
import { RoundBar } from './RoundBar.tsx'
import { DropdownInput, IDropdownInputItem } from '../Inputs'
import { Skeleton } from '../Skeleton'
import { CHART_Y_AXIS_SIGNIFICANT_FIGURES } from './constants.ts'

export const BarChart: FunctionComponent<Omit<IChartProps, 'start' | 'end'>> = (props) => {
    const { title, data = [], loading } = props
    const [durationIndex, setDurationIndex] = useState(0)

    const oldestDate = new Date(getOldestChartData(data)?.name ?? Date.now())

    const [endDate, setEndDate] = useState(new Date(getNewestChartData(data)?.name ?? Date.now()))
    const [startDate, setStartDate] = useState(new Date(endDate.getTime() - 7 * MS_PER_DAY))

    const filteredData = collapseChartData(filterChartDataByDate(data, startDate, endDate))

    const dropdownInputItems: IDropdownInputItem[] = [
        {
            text: 'Last 7 days',
            active: true,
        },
        {
            text: 'Last 30 days',
            active: false,
        },
        {
            text: 'Last 60 days',
            active: false,
        },
        {
            text: 'Last 3 months',
            active: false,
        },
        {
            text: 'Last 6 months',
            active: false,
        },
        {
            text: 'Last year',
            active: false,
        },
    ]
        .map((item, idx) => ({ ...item, onClick: () => setDurationIndex(idx) }))
        .filter((_, idx) => {
            const hypotheticalStartDate = getStartDate(endDate, getDurationFromIndex(idx))
            return hypotheticalStartDate.getTime() >= oldestDate.getTime()
        })

    useMemo(() => {
        setEndDate(new Date(getNewestChartData(data)?.name ?? Date.now()))
    }, [data])

    useMemo(() => {
        setStartDate(getStartDate(endDate, getDurationFromIndex(durationIndex)))
    }, [durationIndex, endDate])

    function getStartDate(endDate: Date, duration: number): Date {
        return new Date(endDate.getTime() - duration)
    }

    function getDurationFromIndex(durationIndex: number): number {
        switch (durationIndex) {
            default:
            case 0:
                return 7 * MS_PER_DAY
            case 1:
                return MS_PER_MONTH
            case 2:
                return 2 * MS_PER_MONTH
            case 3:
                return 3 * MS_PER_MONTH
            case 4:
                return 6 * MS_PER_MONTH
            case 5:
                return MS_PER_YEAR
        }
    }

    return (
        <div className='bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)] shadow-md'>
            {title && !loading && (
                <div className='min-h-[72px] flex flex-row items-center justify-between px-8 py-4'>
                    <Text type='header4' color='text-primary'>
                        {truncateString(title.title, 64)}
                    </Text>
                    <DropdownInput items={dropdownInputItems} />
                </div>
            )}
            {loading ? (
                <Skeleton height={245} />
            ) : (
                <ResponsiveContainer width='100%' height={500}>
                    <_BarChart
                        width={500}
                        height={300}
                        data={cleanChartData(filteredData)}
                        margin={{
                            top: 32,
                            right: 32,
                            bottom: 64,
                            left: 8,
                        }}
                    >
                        <CartesianGrid strokeDasharray='3 3' vertical={false} />
                        <XAxis
                            type={'category'}
                            domain={['dataMin', 'dataMax']}
                            dataKey='name'
                            fontFamily='SpaceGrotesk'
                            dy={10}
                            angle={-45}
                            textAnchor={'end'}
                            fontSize={10}
                            tickFormatter={formatDate}
                        />
                        <YAxis
                            fontFamily='SpaceGrotesk'
                            dx={-5}
                            tickFormatter={(val) =>
                                parseFloat(val.toPrecision(CHART_Y_AXIS_SIGNIFICANT_FIGURES)).toString()
                            }
                        />
                        {/* eslint-disable @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Bar dataKey={'pv'} stackId={1} fill={'#F09'} shape={RoundBar} />
                        <Tooltip content={<CustomTooltip />} cursor={{ fill: '#ffffff80' }} />
                    </_BarChart>
                </ResponsiveContainer>
            )}
        </div>
    )
}
