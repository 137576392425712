import { FunctionComponent, useContext } from 'react'
import {
    CartesianGrid,
    LineChart as _LineChart,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { calculateChartYAxisDomain, formatDate, Style, truncateString } from '@lib/utils'
import classes from './Chart.module.scss'
import { IconName } from '../Icon'
import { Link } from '../Link'
import { Text } from '../Text'
import { CustomTooltip } from './CustomTooltip.tsx'
import { IChartData, IChartProps } from './types.ts'
import { cleanChartData } from './utils.ts'
import { AppSettingsContext } from '@lib/app/settings'
import { Skeleton } from '../Skeleton'
import { CHART_Y_AXIS_SIGNIFICANT_FIGURES } from './constants.ts'

export const LineChart: FunctionComponent<IChartProps> = (props) => {
    const { title, titleTruncation = 0, data, start, end, loading = false, height = 245, onNewTooltip } = props
    const filteredData = filterDataByDate(data, start, end)
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    const chartTitle = title ? (titleTruncation > 0 ? truncateString(title.title, titleTruncation) : title.title) : ''

    const textStyle: Style = {
        fontFamily: `var(--font)`,
    }

    function filterDataByDate(data: IChartData[], start: number | Date, end: number | Date) {
        return data.filter((item: { name: string | number | Date }) => {
            const itemDate = new Date(item.name)
            return itemDate >= start && itemDate <= end
        })
    }

    return (
        <div className='bg-[var(--surface-0)] dark:bg-[var(--surface-transparent-dark)] border-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)] shadow-md'>
            {title && (
                <div className='min-h-[72px] flex flex-row items-center bg-[var(--surface-1)] dark:bg-[var(--surface-1-dark)] p-4'>
                    <div className='w-full flex flex-row items-center justify-between'>
                        {title.url ? (
                            <Link url={title.url} icon={IconName.ChevronRight} color='text-primary'>
                                <Text type='subtitle1' color='text-primary'>
                                    {chartTitle}
                                </Text>
                            </Link>
                        ) : (
                            <Text type='subtitle1' color='text-primary'>
                                {chartTitle}
                            </Text>
                        )}
                        {title.total && title.label && (
                            <div className='flex flex-row items-end justify-between space-x-4 p-0'>
                                <p
                                    className='font-[500] text-[24px] leading-8 align-text-bottom text-[var(--text-primary)] dark:text-[var(--text-primary-dark)]'
                                    style={{ ...textStyle }}
                                >
                                    {title.total}
                                </p>
                                <p
                                    className='font-[600] text-[16px] leading-6 align-text-bottom text-[var(--text-secondary)] dark:text-[var(--text-secondary-dark)]'
                                    style={{ ...textStyle }}
                                >
                                    {title.label}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {loading ? (
                <div className='max-h-[244px]'>
                    <div className='-translate-y-1'>
                        <Skeleton height={246} />
                    </div>
                </div>
            ) : (
                <div
                    className={`${classes['sensor-load-container']} ${isDark && classes['sensor-load-container-dark']}`}
                >
                    <ResponsiveContainer width='100%' height={height}>
                        <_LineChart
                            width={500}
                            height={300}
                            data={cleanChartData(filteredData)}
                            margin={{
                                top: 8,
                                right: 32,
                                bottom: 40,
                                left: 16,
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis
                                type={'category'}
                                domain={['dataMin', 'dataMax']}
                                dataKey='name'
                                fontFamily='SpaceGrotesk'
                                //label={yUnit}
                                dy={10}
                                angle={-45}
                                textAnchor={'end'}
                                fontSize={10}
                                tickFormatter={formatDate}
                            />
                            <YAxis
                                fontFamily='SpaceGrotesk'
                                dx={-5}
                                tick={{ fontSize: '14px' }}
                                tickFormatter={(val) =>
                                    parseFloat(val.toPrecision(CHART_Y_AXIS_SIGNIFICANT_FIGURES)).toString()
                                }
                                domain={calculateChartYAxisDomain}
                            />
                            <ReferenceLine
                                y={7000}
                                label={{
                                    position: 'left',
                                    value: 'Baseline',
                                    fill: isDark ? '#0dce95' : '#ff00ab',
                                    fontFamily: 'SpaceGrotesk',
                                    dy: 15,
                                    dx: 90,
                                    fontSize: 12,
                                }}
                                stroke='#0DCE95'
                                strokeWidth={2}
                                strokeDasharray='10'
                            />
                            <Line type='monotone' dataKey='pv' stroke='#FF00A8' strokeWidth={2} activeDot={{ r: 6 }} />
                            <Tooltip content={<CustomTooltip onNewTooltip={onNewTooltip} />} />
                        </_LineChart>
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    )
}
