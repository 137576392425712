import { BaseApiService } from '@lib/core/api'

export class AuthApiService extends BaseApiService {
    constructor(baseUrl: string) {
        super(baseUrl)
    }

    async status(): Promise<unknown> {
        return this.get('api/auth/session-status')
    }

    async login(username: string, token: string, refreshToken: string): Promise<unknown> {
        return this.post('api/auth/login', { username, token, refreshToken })
    }

    async logout() {
        return this.get('api/auth/logout')
    }

    async createPaymentIntent(
        customerId: string,
        productId: string,
        feeId: string,
        returnUrl: string,
        projectId: string
    ) {
        return this.post('api/auth/create-payment-intent', { customerId, productId, feeId, returnUrl, projectId })
    }
}
