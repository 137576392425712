import { useAuth0 } from '@auth0/auth0-react'
import { AppProvider } from '@components/app'
import { Loading, NotificationProvider } from '@components/base'
import { RouterProvider } from '@pages/Router'

export default function App() {
    const { isLoading, error } = useAuth0()

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        console.error('Error ', error)
    }

    return (
        <AppProvider>
            <NotificationProvider>
                <RouterProvider />
            </NotificationProvider>
        </AppProvider>
    )
}
