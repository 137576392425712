import { useLocation, useParams } from 'react-router-dom'
import { cargoAverage, cargoCondidence, cargoCurrent, liquidWaste } from '@assets'
import { FunctionComponent, useEffect, useState } from 'react'
import { dateToReadable } from '@lib/utils'
import { ProjectDataSourceImage, ProjectLayout, ProjectSensorDataTable } from '@components/app'
import { BarChart, Pane, Skeleton, Tabs, Text } from '@components/base'
import {
    exportDataToFile,
    getSensorDataFromDashboardContext,
    ISensorContextData,
    ISensorData,
    NestedReadingValue,
    mapSensorDataToAnnotations,
    sortSensorData,
} from '@lib/app/sensor'
import { ProjectDataSourceTitle } from '../ProjectDataSourceTitle'
import { IChartData } from '@components/base/Charts/types.ts'
import { sortNestedReadings, transformReadings } from '@pages'
import { PublicAssetPath } from '@pages/Project/ProjectDataSources/ProjectDataSource/Sources/index.ts'

const feedstockDataEmpty: Array<{
    img: string
    text: string
    value: NestedReadingValue
    valueUnit: string
}> = [
    {
        img: cargoCurrent,
        text: 'Current load',
        value: { type: 'Empty' },
        valueUnit: '',
    },
    {
        img: cargoAverage,
        text: 'Daily average load',
        value: { type: 'Empty' },
        valueUnit: '',
    },
    {
        img: cargoCondidence,
        text: 'Data confidence',
        value: { type: 'Empty' },
        valueUnit: '%',
    },
]

export const FeedstockDataSource: FunctionComponent<{ sensorContext?: ISensorContextData }> = (props) => {
    const [activeTab, setActiveTab] = useState(0)

    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    const { sensorContext } = props
    const imageUri =
        PublicAssetPath + (sensorContext?.assetUrl || 'assets/' + slug + '/sensor.' + sensorContext?.equipment.name)

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [feedstockData, setFeedstockData] = useState<
        Array<{
            img: string
            text: string
            value: NestedReadingValue
            valueUnit: string
        }>
    >([{ ...feedstockDataEmpty[0] }, { ...feedstockDataEmpty[1] }, { ...feedstockDataEmpty[2] }])

    const [sensorDataList, setSensorDataList] = useState<ISensorData[]>([])
    const [graphData, setGraphData] = useState<
        Array<{
            equipment: string
            data: IChartData[]
        }>
    >([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensorContext) {
            const sorted = transformReadings(sensorContext.readings)
            const newGraphData = sortNestedReadings(sorted, sensorContext.equipment.name)
            setGraphData(newGraphData)

            const readings = Array.from(Object.values(sensorContext.readings)).map((v) => {
                return getSensorDataFromDashboardContext(v, sensorContext.equipment.units)
            })

            setSensorDataList(readings)
            setLoading(false)
        }
    }, [sensorContext])

    useEffect(() => {
        if (sensorContext) {
            const numbers = [...feedstockData]
            numbers[0].value = { type: 'Float', value: Math.round(sensorContext.state.realTimeFlow) }
            numbers[0].valueUnit = sensorContext.equipment.units
            numbers[1].value = { type: 'Float', value: Math.round(sensorContext.state.currentDayAvg) }
            numbers[1].valueUnit = sensorContext.equipment.units
            numbers[2].value = { type: 'Float', value: Math.round(sensorContext.avgcf) }
            setFeedstockData(numbers)
        }
    }, [sensorContext])

    const TitleComponent = () => {
        return (
            <ProjectDataSourceTitle
                title={sensorContext?.equipment.name ?? 'Feedstock'}
                url={url}
                canShowMenu={activeTab === 1}
                onExportClick={async (fileType) => {
                    const sortedSensorData = sortSensorData(
                        Object.entries(sensorContext?.readings ?? []).map(([key, value]) => ({
                            id: key,
                            ...getSensorDataFromDashboardContext(value, sensorContext?.equipment.units ?? ''),
                        }))
                    )
                    await exportDataToFile(fileType, [sortedSensorData, mapSensorDataToAnnotations(sortedSensorData)], {
                        filename: sensorContext?.id ?? 'Feedstock',
                        sheetNames: ['Readings', 'Annotations'],
                        useHeaderRow: true,
                    })
                }}
            />
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']} onTabClick={setActiveTab}>
                <article className='flex flex-col space-y-8'>
                    <div className='flex flex-row items-center justify-between space-x-4 p-8 clipped bg-surface-transparent dark:bg-surface-transparent-dark'>
                        <div className='min-h-[300px]'>
                            <ProjectDataSourceImage
                                uri={imageUri}
                                alt='Liquid Waste Image'
                                defaultImage={liquidWaste}
                            />
                        </div>
                        <div className='w-full flex flex-col space-y-4 justify-between'>
                            <div className='w-full flex flex-row items-center justify-between space-x-4'>
                                {feedstockData.map((data, index) => (
                                    <div
                                        className='w-1/3 h-full bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-col items-center justify-between p-6 space-y-4'
                                        key={index}
                                    >
                                        <img src={data.img} alt='Image' />
                                        <Text type='body1' color='text-secondary'>
                                            {data.text}
                                        </Text>
                                        <h5 className='font-32-28-500 text-text-primary dark:text-text-primary-dark'>
                                            {data.value.type == 'Float' || data.value.type == 'Int'
                                                ? data.value.value
                                                : 0}{' '}
                                            <span className='font-24-28-400 text-[var(--text-secondary)]'>
                                                {data.valueUnit}
                                            </span>
                                        </h5>
                                    </div>
                                ))}
                            </div>
                            <div className='w-full bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] p-6'>
                                <Text type='header3'>Liquid Waste Load</Text>
                                <div className='w-full flex flex-row items-center justify-between mt-6'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='text-secondary'>
                                            Source:
                                        </Text>
                                        <Text type='subtitle1'>
                                            {sensorContext?.equipment.id || <Skeleton width={64} />}
                                        </Text>
                                    </div>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='text-secondary'>
                                            Last updated:
                                        </Text>
                                        <Text type='subtitle1'>
                                            {sensorContext ? (
                                                dateToReadable(new Date(sensorContext.lastUpdated))
                                            ) : (
                                                <Skeleton width={64} />
                                            )}
                                        </Text>
                                    </div>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='text-secondary'>
                                            Units:
                                        </Text>
                                        <Text type='subtitle1'>
                                            {sensorContext?.equipment.units || <Skeleton width={64} />}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pane>
                        {Object.entries(graphData).map((v) => {
                            const [_, nested] = v
                            return (
                                <div className='p-2'>
                                    <BarChart
                                        title={{ title: nested.equipment }}
                                        data={nested.data}
                                        loading={!feedstockData}
                                    />
                                </div>
                            )
                        })}
                    </Pane>
                </article>
                <ProjectSensorDataTable loading={loading} sensorDataList={sensorDataList} />
            </Tabs>
        </ProjectLayout>
    )
}
