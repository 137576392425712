import { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { ProjectDocumentationPopup } from '@components/app'
import { ProjectDocumentType } from '@lib/app/project'
import { Skeleton } from '@components/base'

interface IProjectImagePropsType {
    uri: string
    defaultImage: any
    alt: string
}

export const ProjectDataSourceImage: FunctionComponent<IProjectImagePropsType> = (props) => {
    const { uri, defaultImage, alt } = props
    const fileName = uri.split('/').pop()

    const DefaultImage = <img src={defaultImage} alt={alt} />

    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
    const [image, setImage] = useState<ReactElement | null>(null)
    const [loading, setLoading] = useState<boolean>(true)

    // Helper function to validate image URL
    async function validateUrl(url: string) {
        return fetch(`${url}`, { method: 'HEAD' })
            .then((response) => {
                if (response.ok) {
                    return `${url}`
                }
                return null
            })
            .catch(() => {
                return null
            })
    }

    useEffect(() => {
        validateUrl(uri).then((validUri) => {
            validUri && setImage(<img src={validUri} alt={alt} style={{ maxHeight: '100%' }} />)
            setLoading(false)
        })
    }, [])

    function onClosePopup(): void {
        setIsPopupOpen(false)
        validateUrl(uri).then((validUri) => {
            validUri && setImage(<img src={validUri} alt={alt} style={{ maxHeight: '100%' }} />)
        })
    }

    function openModal(): void {
        setIsPopupOpen(true)
    }

    return (
        <div
            style={{
                height: '80%',
                width: 'auto',
                position: 'relative',
                display: 'inline-block',
                maxHeight: '50vh',
                margin: '0 auto',
            }}
        >
            {loading ? (
                <Skeleton width='100%' height='100%' />
            ) : (
                <>
                    {image ? image : DefaultImage}
                    <button
                        onClick={openModal}
                        style={{
                            width: 'fit-content',
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                            padding: '4px 16px',
                            backgroundColor: 'var(--text-brand-primary)',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '12px',
                        }}
                    >
                        Upload
                    </button>
                    {isPopupOpen && (
                        <ProjectDocumentationPopup
                            onClose={onClosePopup}
                            uploadType={ProjectDocumentType.Asset}
                            fileName={fileName}
                        />
                    )}
                </>
            )}
        </div>
    )
}
