import { DEFAULT_NOTIFICATION } from './notification.constants.ts'
import { INotification, INotificationSystem } from './notification.types.ts'

class NotificationSystemImpl implements INotificationSystem {
    private static instance: NotificationSystemImpl

    private addNotification?: (notification: Omit<INotification, 'id'>) => void

    static getInstance(): INotificationSystem {
        if (!NotificationSystemImpl.instance) {
            NotificationSystemImpl.instance = new NotificationSystemImpl()
        }
        return NotificationSystemImpl.instance
    }

    initialize(addNotification: (notification: Omit<INotification, 'id'>) => void): void {
        if (this.addNotification) {
            throw new Error('The notification system has already been initialized.')
        }
        this.addNotification = addNotification
    }

    push(notification: Omit<INotification, 'id'>): void {
        if (!this.addNotification) {
            throw new Error('The notification system has not been initialized.')
        }
        this.addNotification({
            ...DEFAULT_NOTIFICATION,
            ...notification,
        })
    }
}

export const NotificationSystem = NotificationSystemImpl.getInstance()
