import { FunctionComponent, useContext } from 'react'
import { CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Area, AreaChart } from 'recharts'
import { calculateChartYAxisDomain, formatDate, truncateString } from '@lib/utils'
import classes from './Chart.module.scss'
import { IconName } from '../Icon'
import { Link } from '../Link'
import { Text } from '../Text'
import { CustomTooltip } from './CustomTooltip.tsx'
import { IChartData, IChartProps } from './types.ts'
import { cleanChartData } from './utils.ts'
import { AppSettingsContext } from '@lib/app/settings'
import { Skeleton } from '../Skeleton'
import { CHART_Y_AXIS_SIGNIFICANT_FIGURES } from './constants.ts'

export const LineAreaChart: FunctionComponent<IChartProps> = (props) => {
    const { title, titleTruncation = 0, data, start, end, loading = false, height = 245, onNewTooltip, unit } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const filteredData = filterDataByDate(data, start, end)

    const chartTitle = title ? (titleTruncation > 0 ? truncateString(title.title, titleTruncation) : title.title) : ''

    function filterDataByDate(data: IChartData[], start: number | Date, end: number | Date) {
        return data.filter((item: { name: string | number | Date }) => {
            const itemDate = new Date(item.name)
            return itemDate >= start && itemDate <= end
        })
    }

    return (
        <div className='bg-[var(--surface-0)] dark:bg-[var(--surface-transparent-dark)] border-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)] shadow-md'>
            {title && !loading && (
                <div className='min-h-[72px] bg-[var(--surface-1)] dark:bg-[var(--surface-1-dark)] flex flex-row items-center p-4'>
                    <div className='w-full flex flex-row items-center justify-between space-x-8 text-nowrap'>
                        {title.url ? (
                            <Link url={title.url} icon={IconName.ChevronRight} color='text-primary'>
                                <Text type='subtitle1' color='text-primary'>
                                    {chartTitle}
                                </Text>
                            </Link>
                        ) : (
                            <Text type='subtitle1' color='text-primary'>
                                {chartTitle}
                            </Text>
                        )}
                        {title.total && title.label && (
                            <div className='flex flex-row items-end justify-between space-x-2 p-0'>
                                <Text type='header3'>{title.total}</Text>
                                <Text type='subtitle1' color='text-secondary'>
                                    {title.label}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {loading ? (
                <Skeleton height={245} />
            ) : (
                <div
                    className={`${classes['sensor-load-container']} ${isDark && classes['sensor-load-container-dark']}`}
                >
                    <ResponsiveContainer width='97%' height={height}>
                        <AreaChart
                            data={cleanChartData(filteredData)}
                            width={500}
                            height={300}
                            margin={{
                                top: 8,
                                right: 16,
                                bottom: 40,
                                left: 50,
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis
                                type={'category'}
                                domain={['dataMin', 'dataMax']}
                                dataKey='name'
                                fontFamily='SpaceGrotesk'
                                dy={10}
                                angle={-45}
                                textAnchor={'end'}
                                fontSize={10}
                                tickFormatter={formatDate}
                            />
                            <YAxis
                                fontFamily='SpaceGrotesk'
                                dx={-5}
                                tick={{ fontSize: '14px' }}
                                tickFormatter={(val) =>
                                    parseFloat(val.toPrecision(CHART_Y_AXIS_SIGNIFICANT_FIGURES)).toString()
                                }
                                domain={calculateChartYAxisDomain}
                                label={{
                                    value: unit,
                                    angle: -90,
                                    position: 'insideLeft',
                                    dx: -20,
                                    dy: 50,
                                }}
                            />
                            <Area
                                type='monotone'
                                dataKey='uv'
                                stroke='#32FFC2'
                                fill='#32FFC21A'
                                strokeWidth={2}
                                activeDot={{ r: 6 }}
                            />
                            <Tooltip content={<CustomTooltip onNewTooltip={onNewTooltip} />} />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    )
}
