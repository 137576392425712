import { IProjectDetails, Pin } from '@lib/app/project'
import { Link, useParams } from 'react-router-dom'
import { strToSentenceCase, truncateString } from '@lib/utils'
import { useState } from 'react'
import sitePlanClasses from '@components/app/SitePlan/SitePlan.module.scss'
import { ProjectStatistic } from '@components/app'
import { GoogleMaps } from '@components/app/Project/GoogleMaps.tsx'
import { OutsideAlerter, Pane, Skeleton, Text } from '@components/base'
import classes from '@pages/Project/ProjectDashboard/ProjectDashboardPage.module.scss'

export const FullScreenMapDash = (props: IProjectDetails) => {
    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    console.log('props ', props)
    const pins: Pin[] = Object.values(props.sensors.sensors).map((sensor) => {
        return {
            sensorID: strToSentenceCase(sensor.id),
            sensorSlug: sensor.id,
            class: '',
            class2: '',
            title: sensor.id,
            model: sensor.equipment.id,
            flow: String(sensor.total),
            average: String(sensor.avgcf),
        }
    })

    const [show, setShow] = useState(false)
    const dcf = {
        type: 'chart',
        amount: props.avgDcf ?? '0',
        unit: '%',
        text: 'Data Confidence',
    }

    const showSensorInfo = () => {
        console.log('showSensorInfo ', show)
        setShow(!show)
    }

    return (
        <div className={sitePlanClasses.sitePlan}>
            <div
                style={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                    padding: '10px',
                    transform: 'translate(-10%, 70%)',
                    zIndex: 500,
                    justifyContent: 'right',
                    background: 'white',
                }}
            >
                <ProjectStatistic statistic={dcf} />
            </div>
            <GoogleMaps {...{ location: props.location, style: { height: '80vh', width: '100%' } }} />
            <div
                style={{
                    transform: 'translate(-50%, -100%)',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    height: 'fit-content',
                    width: 'fit-content',
                    minHeight: '50px',
                    minWidth: '50px',
                    cursor: 'pointer',
                    zIndex: 1000,
                }}
            >
                <div
                    className={`${sitePlanClasses.pinContainer}`}
                    style={{ cursor: 'pointer', height: '25px', width: '25px' }}
                    onClick={() => showSensorInfo()}
                />
                {show && (
                    <OutsideAlerter onClick={() => setShow(false)}>
                        <div
                            className={classes.clipped}
                            style={{ background: 'white', transform: 'translate(+30%, 0%)' }}
                        >
                            <Pane>
                                <div
                                    className={`${sitePlanClasses.sensorInfo} ${sitePlanClasses.visible}`}
                                    style={{
                                        maxHeight: '60vh',
                                        overflow: 'auto',
                                        scrollbarWidth: 'none',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className={sitePlanClasses.sensorTitleContainer}>
                                        <Text type={'header3'} justification={'center'}>
                                            Data Sources
                                        </Text>

                                        {props.projectInfo.projectDev === 'LI-COR' && (
                                            <img
                                                src={
                                                    'https://demia-public.s3.us-east-1.amazonaws.com/testing/image+(9).png'
                                                }
                                                alt={'Data Sources'}
                                                height={100}
                                                width={100}
                                                style={{ margin: '0 auto' }}
                                            />
                                        )}
                                    </div>

                                    {pins.map((pin) => {
                                        return (
                                            <div>
                                                <div className={sitePlanClasses.infoContainer}>
                                                    <div className={sitePlanClasses.singleItem}>
                                                        <h4>Sensor ID</h4>
                                                        <p>{truncateString(pin.sensorID, 30)}</p>
                                                    </div>
                                                    <div className={sitePlanClasses.singleItem}>
                                                        <h4>Model</h4>
                                                        <p>{truncateString(pin.model ?? '', 30)}</p>
                                                    </div>
                                                </div>
                                                <Link
                                                    to={`${url}/${pin.sensorSlug}`}
                                                    className={sitePlanClasses.sensorLink}
                                                >
                                                    View sensor
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Pane>
                        </div>
                    </OutsideAlerter>
                )}
            </div>
            <div style={{ transform: 'translate(0, -110%)', zIndex: 500 }}>
                <div className='flex flex-row space-x-10 px-6 py-2'>
                    <div className={sitePlanClasses.clipped} style={{ background: 'white' }}>
                        <Pane>
                            <div className='space-y-1 bg-white'>
                                <Text type='body2' color='text-secondary'>
                                    Project Type
                                </Text>
                                <Text type='body1'>{props.projectInfo.type}</Text>
                            </div>
                        </Pane>
                    </div>
                    <div className={sitePlanClasses.clipped} style={{ background: 'white' }}>
                        <Pane>
                            <div className='space-y-1 bg-white'>
                                <Text type='body2' color='text-secondary'>
                                    Methodology
                                </Text>
                                <Text type='body1'>{props.projectInfo.methodology}</Text>
                            </div>
                        </Pane>
                    </div>
                    <div className={sitePlanClasses.clipped} style={{ background: 'white' }}>
                        <Pane>
                            <div className='space-y-1 bg-white'>
                                <Text type='body2' color='text-secondary'>
                                    Project Developer
                                </Text>
                                <Text type='body1'>{props.projectInfo.projectDev}</Text>
                            </div>
                        </Pane>
                    </div>
                    <div className={sitePlanClasses.clipped} style={{ background: 'white' }}>
                        <Pane>
                            <div className='space-y-1 bg-white'>
                                <Text type='body2' color='text-secondary'>
                                    Project ID
                                </Text>
                                <Text type='body1'>{props.projectInfo.projectId || <Skeleton />}</Text>
                            </div>
                        </Pane>
                    </div>
                </div>
            </div>
        </div>
    )
}
