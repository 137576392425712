import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { INotification, NotificationType } from '@lib/core/notification'
import { AppSettingsContext } from '@lib/app/settings'
import { Icon, IconName } from '@components/base/Icon'
import { Text } from '@components/base/Text'

interface IToastProps extends INotification {
    onClose: () => void
}

const TOAST_FADE_OUT_TIME: number = 300

const BACKGROUND_COLOR_MAP: Record<NotificationType, string> = {
    error: 'bg-surface-danger dark:bg-surface-danger-dark',
    warning: 'bg-surface-warning dark:bg-surface-warning-dark',
    success: 'bg-surface-success dark:bg-surface-success-dark',
    info: 'bg-surface-info dark:bg-surface-info-dark',
}

export const Toast: FunctionComponent<IToastProps> = (props) => {
    const { type, message, duration, dismissible, persistent, action, onClose } = props
    const [isVisible, setIsVisible] = useState(true)
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    function handleClose(): void {
        setIsVisible(false)
        setTimeout(onClose, TOAST_FADE_OUT_TIME)
    }

    function handleActionCallback(): void {
        action?.callback()
        setIsVisible(false)
        setTimeout(onClose, TOAST_FADE_OUT_TIME)
    }

    useEffect(() => {
        if (!persistent && duration !== undefined && duration > 0) {
            const timer = setTimeout(() => {
                setIsVisible(false)
                setTimeout(onClose, TOAST_FADE_OUT_TIME)
            }, duration)
            return () => clearTimeout(timer)
        }
    }, [persistent, duration, onClose])

    return (
        <div
            className={`animate-fade-in transition-all duration-300 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2'} ${BACKGROUND_COLOR_MAP[type]} shadow-lg px-4 py-2 clipped-right pointer-events-auto`}
        >
            <div className='flex flex-row items-center justify-between space-x-4'>
                <div className='flex flex-row items-center justify-between space-x-4 divide-x divide-text-invert dark:divide-text-primary'>
                    {action && (
                        <button
                            onClick={handleActionCallback}
                            style={{ background: 'transparent' }}
                            className='w-fit px-4 -mx-4 -my-4'
                        >
                            <Text type='subtitle2' freezeColor color={isDark ? 'text-primary' : 'text-invert'}>
                                {action.label}
                            </Text>
                        </button>
                    )}
                    <div className={action && 'pl-4'}>
                        <Text type='subtitle2' freezeColor color={isDark ? 'text-primary' : 'text-invert'}>
                            {message}
                        </Text>
                    </div>
                </div>
                {dismissible && (
                    <button onClick={handleClose} style={{ background: 'transparent' }} className='w-fit p-0'>
                        <Icon name={IconName.Close} color={isDark ? 'text-primary' : 'text-invert'} />
                    </button>
                )}
            </div>
        </div>
    )
}
