import { FunctionComponent, MouseEvent, useContext, useRef, useState } from 'react'
import { Button, Icon, IconName, Link, Popover, Text } from '@components/base'
import { AppSettingsContext } from '@lib/app/settings'
import { FEATURES } from '@features'
import { DataExportFileType } from '@lib/app/sensor'

interface IProjectDataSourceTitleProps {
    title: string
    url: string
    onExportClick: (fileType: DataExportFileType) => Promise<void>
    canShowMenu?: boolean
}

export const ProjectDataSourceTitle: FunctionComponent<IProjectDataSourceTitleProps> = (props) => {
    const { title, url, canShowMenu, onExportClick } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const [showMenu, setShowMenu] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [isExportingFileType, setIsExportingFileType] = useState<DataExportFileType | null>(null)
    const [hadOutsideClick, setHadOutsideClick] = useState(false)
    const menuRef = useRef<HTMLDivElement | null>(null)

    function onMenuClick(event?: MouseEvent<HTMLButtonElement>): void {
        if (event?.target) {
            if (hadOutsideClick) {
                // Do nothing.
            } else {
                setShowMenu(!showMenu)
            }
        } else {
            setShowMenu(false)
            setHadOutsideClick(true)
            setTimeout(() => {
                setHadOutsideClick(false)
            }, 200)
        }
    }

    async function onExportClickWrapper(fileType: DataExportFileType): Promise<void> {
        try {
            setIsExportingFileType(fileType)
            setErrorText('')
            await onExportClick(fileType)
            setShowMenu(false)
        } catch (err) {
            console.error(err)
            setErrorText(`Unable to create ${fileType.toUpperCase()} file`)
        } finally {
            setIsExportingFileType(null)
        }
    }

    return (
        <div className='w-full flex flex-row items-center justify-between'>
            <Link url={url} icon={IconName.ArrowLeft} color='text-primary' reverse>
                <Text type='header2'>{title}</Text>
            </Link>
            {FEATURES.project.dataSources.exportData.enabled && canShowMenu && (
                <div ref={menuRef} className='w-auto z-10'>
                    <Button variant='outline' size='medium' onClick={onMenuClick}>
                        <Icon name={IconName.Hamburger} color={isDark ? 'text-primary-dark' : 'text-primary'} />
                    </Button>
                    {showMenu && menuRef && (
                        <Popover anchor={menuRef.current as HTMLDivElement} event='click' onOutsideClick={onMenuClick}>
                            <div className='min-w-[300px] flex flex-col justify-between space-y-8 px-4 py-6'>
                                <Text type='header3'>Export</Text>
                                <div className='flex flex-col items-end justify-between space-y-2'>
                                    {FEATURES.project.dataSources.exportData.csv.enabled && (
                                        <Button
                                            variant='outline'
                                            width='3/4'
                                            icon={IconName.Export}
                                            onClick={() => onExportClickWrapper('csv')}
                                            busy={isExportingFileType === 'csv'}
                                            busyText='Exporting...'
                                        >
                                            Export to CSV
                                        </Button>
                                    )}
                                    {FEATURES.project.dataSources.exportData.xlsx.enabled && (
                                        <Button
                                            variant='outline'
                                            width='3/4'
                                            icon={IconName.Export}
                                            onClick={() => onExportClickWrapper('xlsx')}
                                            busy={isExportingFileType === 'xlsx'}
                                            busyText='Exporting...'
                                        >
                                            Export to XLSX
                                        </Button>
                                    )}
                                </div>
                                {errorText && (
                                    <div className='self-end'>
                                        <Text type='body1' color='text-danger'>
                                            {errorText}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </Popover>
                    )}
                </div>
            )}
        </div>
    )
}
