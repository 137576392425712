const storageKeys = ['appSettings', 'projectSettings', 'routerState'] as const
export type StorageKey = (typeof storageKeys)[number]

export class StorageManager {
    static save<T>(key: StorageKey, value: T): void {
        try {
            const serializedValue = JSON.stringify(value)
            localStorage.setItem(key, serializedValue)
        } catch (err) {
            console.error(`Unable to save data to localStorage: ${err}`)
        }
    }

    static load<T>(key: StorageKey): T | undefined {
        try {
            const serializedValue = localStorage.getItem(key)
            return serializedValue ? (JSON.parse(serializedValue) as T) : undefined
        } catch (err) {
            console.error(`Unable to load data from localStorage: ${err}`)
        }
    }

    static delete(key: StorageKey): void {
        try {
            localStorage.removeItem(key)
        } catch (err) {
            console.error(`Unable to delete data in localStorage: ${err}`)
        }
    }

    static exists(key: StorageKey): boolean {
        return localStorage.getItem(key) !== null
    }
}
