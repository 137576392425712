import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ISensorContextData } from '@lib/app/sensor'
import { CargoDataSource, FeedstockDataSource, FlowmeterDataSource } from './Sources'
import { ProjectContext } from '@lib/app/project'
import { NestedDataSource } from '@pages/Project/ProjectDataSources/ProjectDataSource/Sources/NestedDataSource.tsx'

const CARGO: string = 'cargo'
const FLOW: string = 'flow'
const FEED: string = 'feed'
const NESTED: string = 'nested'
export type SensorTypes = typeof CARGO | typeof FLOW | typeof FEED | typeof NESTED

function sensorToTemplate(sensor: string): SensorTypes {
    sensor = sensor.toLowerCase()
    if (sensor.includes('cargo') || sensor.includes('grape')) {
        return CARGO
    } else if (sensor.includes('flow')) {
        return FLOW
    } else if (sensor.includes('feed')) {
        return FEED
    } else {
        return NESTED
    }
}

export const ProjectDataSourcePage = () => {
    const { sensorsSlug } = useParams()
    const { details } = useContext(ProjectContext).data
    const [sensorContext, setSensorContext] = useState<ISensorContextData | null>(null)

    useEffect(() => {
        if (details) {
            const _sensor = Object.values(details.sensors.sensors).find(
                (sensorContext) => sensorContext.id === sensorsSlug
            )
            _sensor && setSensorContext(_sensor)
        }
    }, [details])

    if (sensorContext) {
        const type = sensorToTemplate(sensorsSlug!)
        switch (type) {
            case CARGO:
                return <CargoDataSource sensorContext={sensorContext} />
            case FLOW:
                return <FlowmeterDataSource sensorContext={sensorContext} />
            case FEED:
                return <FeedstockDataSource sensorContext={sensorContext} />
            case NESTED:
                return <NestedDataSource sensorContext={sensorContext} />
        }
    } else {
        return <></>
    }
}
